import React, { Component, useCallback, useContext } from 'react';
import {
    Row, Col, Form, FormGroup, Label, Input, Button, Modal, ModalBody
} from 'reactstrap';
import styles from './Payment.module.css';
import '../css/TheftLoss.css'
import classNames from 'classnames';
import { DropDownAutoComplete } from '../../../../components/inputs/DropDownAutoComplete';
import SimpleReactValidator from 'simple-react-validator';
import Cleave from 'cleave.js/react';
import { ReactComponent as Info } from '../../../../assets/images/info-icon-blk.svg'
import ReactTooltip from 'react-tooltip';
import { Spinner } from 'react-bootstrap';
import { DropDownInput } from '../../../../components/inputs/DropDownInput';
import CancelModal from '../../../../components/modals/CancelModal';
import SaveModal from '../../../../components/modals/SaveModal';
import moment from 'moment';
import DocumentTitle from 'react-document-title';
import { SkipNavLink, SkipNavContent } from "@reach/skip-nav";
import { updatePartnerCase, createRepair, updateRepair, getTUDetailedDecision, addTUCase, getTUToken, getTUSessionId, addTUStatus, getTUGlobalIDVDecision, createNewAuthorization, fetchPaymentSession, processResponse, createClaimHistoryItem, getApplePayDetails, countryNameFromCode, createOrUpdateClaimConfiguration } from '../../../../helpers';
import { getLocationFromZip } from '../../../api/addressverification';
import { CreditCardInput } from '../../../../components/inputs/CreditCardInput';
import { billingCodeDisplayIds, authorizationStatus } from '../../../Global/AdjudicationConstants';
import { ApplePayButton } from "react-apple-pay-button";
import paymentRequest from 'react-payment-request-api';
import { StaticAddress, AddressForm } from './CountryAddressForms';
import { UseTranslationInClassComponent } from '../../../contexts/LanguageTranslation/UseTranslationInClassComponent'
import { LanguageContext } from '../../../contexts/LanguageTranslation/LanguageContext';
import { ContractContext } from '../../../contexts/ContractContext';
import IntakeLossButtons from './IntakeLossButtons';
import CreditCardIcons from '../../../shared/PaymentComponents/CreditCardIcons/CreditCardIcons';
import ProcessingModal from '../../../../components/modals/ProcessingModal';
import { CombinedLangContractContext } from '../../../contexts/ProviderLangContractContext';
import { appleServiceNumbers } from '../../../_BrandingProvider/brandContent';
import ApplePayIcon from '../../../../assets/images/Vendor/Apple/apple-pay.svg';
import OutlineIcon from '../../../../assets/images/cc-outline.svg';
import SecurityIcon from '../../../../assets/images/cc-security-code.svg';


let payOption = styles.payOption;
let ml15 = styles.ml15;
let btnPrimary = "btn-primary";
let btn = "btn";
let btnPay = styles.btnPay;
let sessionId = "";

export default class Payment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            onClickBackButton: false,
            amount: this.props.entitlementDetails.deductible,
            clientId: this.props.entitlementDetails.clientId,
            claimNumber: this.props.entitlementDetails.claimNumber,
            caseNumber: this.props.entitlementDetails.caseNumber,
            firstName: this.props.entitlementDetails.firstName,
            lastName: this.props.entitlementDetails.lastName,
            verifiedAddress: {
                address1: this.props.entitlementDetails?.party?.serviceAddress?.address1,
                address2: this.props.entitlementDetails?.party?.serviceAddress?.address2,
                city: this.props.entitlementDetails?.party?.serviceAddress?.city,
                state: this.props.entitlementDetails?.party?.serviceAddress?.state,
                stateId: this.props.entitlementDetails?.party?.serviceAddress?.stateId,
                zip: this.props.entitlementDetails?.party?.serviceAddress?.zip,
                countryCode: this.props.entitlementDetails?.party?.serviceAddress?.countryCode || this.props.entitlementDetails?.countryCode                
            },
            billingAddress: {
                address1: '',
                address2: '',
                city: '',
                state: '',
                stateId: null,
                zip: '',
                countryCode: this.props.entitlementDetails?.countryCode
            },
            paymentMethod: null,
            failedAuthPayments: 0,
            customAddress: false,
            validationFired: false,
            isApplePayEnabled: false,
            continuing: false,
            ccType: 'unknown',
            states: null,
            activeCountries: null,
            applePayMerchantId: "",
            ccNumber: "",
            cardExpires: "",
            securityCode: "",
            callNumber: "866-258-7419",
            errors: {
                ccInvalid: false,
                ccNotSupported: false,
                ccExpired: false,
                ccNumber: false,
                cardExpires: false,
                securityCode: false,
                billingAddress: false
            },
            pageTitle: "",
            rulesRunning: false,
            isApproved: false,
            isProcessing: false,
            shipToStates: null,
            billingStates: null,
            customerVerificationResult: "0",
            authenticationErrorFlag: false
        }
        this.handleSelectedPayment = this.handleSelectedPayment.bind(this);
        this.handleApplePay = this.handleApplePay.bind(this);
        this.handleCheckAddress = this.handleCheckAddress.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.ccTypeChanged = this.ccTypeChanged.bind(this);
        this.handleStateDropDown = this.handleStateDropDown.bind(this);
        this.saveForLater = this.saveForLater.bind(this);
        this.keepOnPage = this.keepOnPage.bind(this);
        this.onBackButtonEvent = this.onBackButtonEvent.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.onRequestApplePay = this.onRequestApplePay.bind(this);
        this.applePay = this.applePay.bind(this);
        this.validator = new SimpleReactValidator({
            validators: {
                card_expires: {
                    message: 'The :attribute is invalid.',
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(val, /^(0[1-9]|1[0-2])\/?([0-9]{2})$/) && params.indexOf(val) === -1
                    },
                    messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),
                    required: true
                },
                card_notsupported: {
                    message: 'The :attribute is not accepted.',
                    rule: (val, params, validator) => {
                        // Discover
                        if (validator.helpers.testRegex(val, /^6(?:011\d{12}|5\d{14}|4[4-9]\d{13}|22(?:1(?:2[6-9]|[3-9]\d)|[2-8]\d{2}|9(?:[01]\d|2[0-5]))\d{10})$/) && params.indexOf(val) === -1) return false;
                        // Amex
                        if (validator.helpers.testRegex(val, /^3[47][0-9]{13}$/) && params.indexOf(val) === -1) return false;
                        return true;
                    },
                    messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),
                    required: true
                },
                cvv: {
                    message: 'The :attribute is invalid.',
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(val, /^[0-9]{3,4}$/) && params.indexOf(val) === -1
                    },
                    messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),
                    required: true
                },
            },
            element: message => <div className="errorMsg" role="alert" aria-live="assertive">{message.charAt(0).toUpperCase() + message.slice(1)}</div>,
            messages: {
                card_num: 'The credit card number is invalid.',
                card_exp: 'The :attribute is invalid.',
                required: ':attribute is required.'
            },
        });
    }

    onSaveForLaterAction = e => {
        e.preventDefault();
        this.setState({ showConfirmation: true });
    }

    handleKeyDown = (e) => {
        console.log('e', e)
        if (e.key === 'Enter') {
            e.preventDefault();
            let { name, value, id } = e.target;

            let checked = false;

            switch (id) {
                case "reach-skip-nav":
                    checked = document.getElementById('paytype1').checked;
                    break;
                case "pay2Div":
                    checked = document.getElementById('paytype2').checked;
                    break;
                default:
                    checked = e.target.checked;
            }

            if (checked === false) {
                switch (id) {

                    case "reach-skip-nav":
                        value = "Applepay";
                        break;
                    case "pay2Div":
                        value = "ccard";
                        break;
                }
            } else {
                value = null;
            }

            this.setState({
                paymentMethod: value,
                validated: false,
            });
        }
    }
    onSaveCancel = e => {
        e.preventDefault();
        this.setState({ showConfirmation: false });
    }

    componentWillMount() {
        let countryCode = this.props.entitlementDetails?.party?.serviceAddress?.countryCode ?? "us";
        let shipToCountryCode = this.props.entitlementDetails?.shipToCountryCode ?? countryCode; 

        let applePaySession = window.ApplePaySession;
        let canMakePayments = typeof applePaySession != "undefined" && applePaySession != null ? applePaySession.canMakePayments() : null;
        let applePayAvailable = typeof applePaySession != "undefined" && applePaySession != null && canMakePayments != null ? true : false;

        if (applePayAvailable) {
            let status = 0;
            getApplePayDetails(this.props.entitlementDetails?.countryCode ?? "us")
                .then(function (res) {
                    status = res.status;
                    return res.json();
                }).then(data => {
                    if (status === 200) {
                        this.setState({
                            applePayMerchantId: data.merchantIdentifier,
                            isApplePayEnabled: data.applePayEnabled
                        })

                    }
                });
        }
        let claimId = this.props.claimId ?? this.props.entitlementDetails?.claimId ?? 0;
        fetch('api/payment/GetFailedTransactions/' + claimId + '/' + this.state.clientId)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    failedAuthPayments: data.filter((payment) => 
                    payment.errorCode.includes('SN-CHASE-AUTHENTICATION-FAILED') 
                    || 
                    payment.errorCode.includes('SN-CHASE-AUTHENTICATION-PENDING')).length,
                })
            })

        fetch('api/party/states/' + countryCode + '/' + this.state.clientId)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    states: data.map(s => {
                        return {
                            data: s.stateId,
                            display: s.name,
                            abbreviation: s.abbreviation
                        }
                    })
                });

                if (shipToCountryCode?.toLowerCase() == countryCode?.toLowerCase()) {
                    this.setState({
                        shipToStates: this.state.states
                    });
                }
            })

        if (shipToCountryCode?.toLowerCase() !== countryCode?.toLowerCase()) {
            fetch('api/party/states/' + shipToCountryCode?.toLowerCase() + '/' + this.state.clientId)
                .then(res => res.json())
                .then(data => {
                    this.setState({
                        shipToStates: data.map(s => {
                            return {
                                data: s.stateId,
                                display: s.name,
                                abbreviation: s.abbreviation
                            }
                        })
                    });
                })
        }

        //Get claim Configurations
        fetch('api/claim/GetClaimConfiguration/' + claimId + '/' + this.state.clientId)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    customerVerificationResult: data?.find(cc => cc.claimAttributeId == 9)?.value ?? "0",
                    authenticationErrorFlag: data?.find(cc => cc.claimAttributeId == 36)?.value ?? "0" == "1",
                });
            });

        //Get active country list
        fetch('api/party/activecountries/' + this.state.clientId)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    activeCountries: data.map(s => {
                        return {
                            countryId: s.countryId,
                            countryCode: s.iso2 == 'AU' ? 'AUS' : s.iso2 == 'GB' ? 'UK' : s.iso2,
                            countryName: s.countryName
                        }
                    })
                });
            });

        // Get SessionId
        sessionId = typeof window.TrustevV2 == "undefined" ? "" : window.TrustevV2.SessionId;
        //console.log(sessionId, window.TrustevV2.SessionId);

        let sessionStatusCode = 0;

        if (sessionId == "" || typeof sessionId == "undefined") {            
            getTUSessionId(this.state.clientId)
                .then(function (resSession) {
                    sessionStatusCode = resSession.status;
                    return resSession.json();
                })
                .then(sessionData => {
                    if (sessionStatusCode === 200) {
                        sessionId = sessionData.sessionId;
                        console.log("Session Id workaround: " + sessionId);
                    }
                    else {
                        console.log("Session Id failed");
                        sessionId = "";
                    }
                });
        }

        // SEU-1862 show credit card option
        // this.setState({
        //     paymentMethod: "ccard"
        // });
    }

    handleStateDropDown = e => {
        let billingAddress = this.state.billingAddress;
        billingAddress["stateId"] = e;
        this.setState({ billingAddress: billingAddress });
    }

    sendAuthenticationErrorEmail = async () => {
        if (this.state.failedAuthPayments >= 2) {
            let claimId = this.props.claimId ?? this.props.entitlementDetails?.claimId ?? 0;
            // Save authentication error flag in claim configuration table
            createOrUpdateClaimConfiguration(claimId, 36, "1", this.state.clientId);
            // Send Authetication error Mail
            fetch('api/communication/'.concat(this.state.clientId), {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({

                    emailModel: {
                        Recipients: [this.props.entitlementDetails.email],
                        Cc: [],
                        Bcc: [],
                        IsBodyHtml: true,
                        emailType: 234
                    },
                    serviceModel: {
                        SerialNumber: this.props.entitlementDetails.contractAsset?.serialNumber,
                        CaseNumber: this.props.entitlementDetails.caseNumber,
                        ClaimId: parseInt(this.props.entitlementDetails.claimId),
                        WarrantyObjectTypeId: 2
                    }
                })
            }).then(function (res) {
                console.log(res);
            })
        }
    }

    handleSelectedPayment = e => {
        const { name, value } = e.target;
        this.setState({
            paymentMethod: value,
            applePayError: false
        });
        console.log(value);
        e.preventDefault();

        if (value == "Applepay") {
            this.onRequestApplePay();
        }
    }

    handleApplePay = e => {
        const { name, value } = e.target;
        this.setState({
            paymentMethod: "Applepay",
            applePayError: false
        });
        console.log(value);
        e.preventDefault();


        this.onRequestApplePay();

    }

    getApprovalStatus = async () => {
        try {
            let claimId = this.props.claimId ?? this.props.entitlementDetails?.claimId ?? 0;
            const res = await fetch('api/history/GetApprovedStatus/' + claimId + '/' + this.state.clientId);
            const data = await res.json();
            this.setState({
                isApproved: data.objectHistoryId != 0 && data.objectHistoryId != undefined ? true : false
            });
        }
        catch (error) {
            console.log('Error fetching approve status.')
        }


    }

    removeQueueItem = e => {
        let claimId = this.props.claimId ?? this.props.entitlementDetails?.claimId ?? 0;
        fetch('api/queue/RemoveItemFromQueue/' + claimId + '/' + this.state.clientId, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(function (res) {
            console.log(res);
        })
    }


    async onRequestApplePay() {
        if (!window.PaymentRequest)
            console.log("PaymentRequest not implemented");//return;
        let applePayRequest = {
            countryCode: 'US',
            currencyCode: 'USD',
            supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
            merchantCapabilities: ['supports3DS'],
            total: { label: 'Your Merchant Name', amount: parseFloat(this.state.amount), },
        };
        this.setState({ rulesRunning: true });
        try {
            const paymentOptions = {
                requestPayerName: true,
                requestPayerEmail: false,
                requestPayerPhone: false,
                requestShipping: false,
                shippingType: "shipping",
            };

            const debitModifier = {
                supportedMethods: "https://apple.com/apple-pay",
                data: { paymentMethodType: "debit" },
                total: {
                    label: "My Merchant",
                    amount: { value: "26.50", currency: "USD" },
                },
                additionalDisplayItems: [{
                    label: "Debit Card Discount",
                    amount: { value: "-1.00", currency: "USD" },
                }],
            };
            let countryCode = this.props.entitlementDetails?.countryCode ?? "us";
            let amount = this.props.entitlementDetails?.deductible ?? this.state.amount;
            let twoLetterCountryCode = "US";
            let currency = "USD";
            let culture = "en-US";

            switch (countryCode.toLowerCase()) {
                case "de":
                    twoLetterCountryCode = "DE";
                    currency = "EUR";
                    culture = "en-DE";
                    break;
                case "aus":
                    twoLetterCountryCode = "AU";
                    currency = "AUD";
                    culture = "en-AU";
                    break;
                case "uk":
                    twoLetterCountryCode = "GB";
                    currency = "GBP";
                    culture = "en-GB";
                    break;
                case "fr":
                    twoLetterCountryCode = "FR";
                    currency = "EUR";
                    culture = "en-FR";
                    break;
                case "it":
                    twoLetterCountryCode = "IT";
                    currency = "EUR";
                    culture = "en-IT";
                    break;
                case "es":
                    twoLetterCountryCode = "ES";
                    currency = "EUR";
                    culture = "en-ES";
                    break;
                case "vn":
                    twoLetterCountryCode = "VN";
                    currency = "VND";
                    culture = "en-VN";
                    break;
                case "cl":
                    twoLetterCountryCode = "CL";
                    currency = "CLP";
                    culture = "en-CL";
                    break;
                case "at":
                    twoLetterCountryCode = "AT";
                    currency = "EUR";
                    culture = "en-AT";
                    break;
                case "dk":
                    twoLetterCountryCode = "DK";
                    currency = "DKK";
                    culture = "en-dk";
                    break;
                case "fi":
                    twoLetterCountryCode = "FI";
                    currency = "EUR";
                    culture = "en-FI";
                    break;
                case "ie":
                    twoLetterCountryCode = "IE";
                    currency = "EUR";
                    culture = "en-ie";
                    break;
                case "nl":
                    twoLetterCountryCode = "NL";
                    currency = "EUR";
                    culture = "en-NL";
                    break;
                case "nz":
                    twoLetterCountryCode = "NZ";
                    currency = "NZD";
                    culture = "en-nz";
                    break;
                case "no":
                    twoLetterCountryCode = "NO";
                    currency = "NOK";
                    culture = "en-no";
                    break;
                case "se":
                    twoLetterCountryCode = "SE";
                    currency = "SEK";
                    culture = "en-SE";
                    break;
                case "ch":
                    twoLetterCountryCode = "CH";
                    currency = "CHF";
                    culture = "en-ch";
                    break;
                default:
                    twoLetterCountryCode = "US";
                    currency = "USD";
                    culture = "en-US";
                    break;
            }

            const paymentDetails = {
                total: {
                    label: "AIG Apple Care",
                    amount: { value: amount, currency: currency },
                },
            };

            const applePayMethod = {
                supportedMethods: "https://apple.com/apple-pay",
                data: {
                    version: 3,
                    merchantIdentifier: this.state.applePayMerchantId,
                    merchantCapabilities: ["supports3DS", "supportsCredit", "supportsDebit"],
                    supportedNetworks: ["amex", "discover", "masterCard", "visa"],
                    countryCode: twoLetterCountryCode.toUpperCase(),
                },
            };
            console.log(paymentDetails, this.state.amount)
            const request = new PaymentRequest([applePayMethod], paymentDetails, paymentOptions);

            request.onmerchantvalidation = function (event) {
                try {
                    // Have your server fetch a payment session from event.validationURL.
                    //console.log(event);
                    const sessionPromise = fetchPaymentSession(event.validationURL, countryCode.toLowerCase());
                    console.log(sessionPromise);
                    let session = event.complete(sessionPromise);
                    console.log(session, event);
                }
                catch (ex) {
                    console.log("onmerchantvalidation", ex);
                }
            };
            //console.log("create response");
            try {
                const response = await request.show();
                //const response = (async function () { await request.show(); })().then(resp => { return resp.json }); //await
                // Process response and pull status
                //console.log(response, request);
                //console.log("Chase Object: " + response.toJSON(), JSON.parse(JSON.stringify(response)));
                var paymentData = response?.details?.token?.paymentData;
                console.log(paymentData);

                //const status = await this.applePay(paymentData);
                let statusCode = 0;
                let status = "";
                let billingAddress = this.props.entitlementDetails?.party?.billAddress ?? this.props.entitlementDetails?.party?.serviceAddress;
                let mailingAddress = this.props.entitlementDetails?.party?.shippingAddress ?? this.props.entitlementDetails?.party?.serviceAddress;
                let shipToCountryCode = this.props.entitlementDetails?.shipToCountryCode ?? countryCode;
                const fetchStatus = async args => {
                    const resFetch = await fetch('api/payment/applePay/'.concat(this.state.clientId), {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            billAddress: {
                                name: this.state.firstName + " " + this.state.lastName,
                                address1: billingAddress?.address1,
                                address2: billingAddress?.address2,
                                city: billingAddress?.city,
                                state: (countryCode.toLowerCase() == "de" || countryCode.toLowerCase() == "fr") ? twoLetterCountryCode : (this.state.states?.find(state => { return state.data == billingAddress?.stateId })?.display.split(' ')[0]?.length > 1 ? this.state.states?.find(state => { return state.data == billingAddress?.stateId })?.display : this.state.states?.find(state => { return state.data == billingAddress?.stateId })?.display?.replace(' ', '_')) ?? twoLetterCountryCode,
                                zip: billingAddress?.zip,
                                countryCode: twoLetterCountryCode.toUpperCase(),
                                phone: parseInt(this.props.entitlementDetails.party.partyPhoneModel[0].number),
                                phoneType: "W"
                            },
                            shipAddress: {
                                name: this.state.firstName + " " + this.state.lastName,
                                address1: mailingAddress?.address1,
                                address2: mailingAddress?.address2,
                                city: mailingAddress?.city,
                                state: (shipToCountryCode.toLowerCase() == "de" || shipToCountryCode.toLowerCase() == "fr") ? shipToCountryCode : (this.state.shipToStates?.find(state => { return state.data == mailingAddress?.stateId })?.display.split(' ')[0]?.length > 1 ? this.state.shipToStates?.find(state => { return state.data == mailingAddress?.stateId })?.display : this.state.shipToStates?.find(state => { return state.data == mailingAddress?.stateId })?.display?.replace(' ', '_')) ?? shipToCountryCode,
                                zip: mailingAddress?.zip,
                                countryCode: shipToCountryCode?.toUpperCase(),
                                phone: parseInt(this.props.entitlementDetails.party.partyPhoneModel[0].number),
                                phoneType: "W",
                                shippingMethod: "C"
                            },
                            sendReceipt: {
                                custReceiptEmail: this.props.entitlementDetails.email,
                                custReceiptPhone: this.props.entitlementDetails.party.partyPhoneModel[0].number
                            },
                            orderId: String(this.props.entitlementDetails.claimId),
                            orderNumber: this.state.claimNumber,
                            Capture: false,
                            amount: parseFloat(this.state.amount),
                            encryptedPaymentBundle: paymentData,
                            countryCode: countryCode
                        })
                    }).then(function (res) {

                        console.log(res);
                        statusCode = res.status;
                        return res.json();
                    }).then(data => {
                        let dataResponse = data;
                        status = "fail";
                        console.log(data);
                        if (statusCode === 200) {
                            if (data?.responseCode?.procStatus == "0" && data?.responseCode?.approvalStatus == "1") {
                                status = "success";
                                this.setState({ txRefNum: data.txRefNum, applePayError: false });
                                this.applePay(paymentData, response);
                            }
                            else {
                                this.setState({ rulesRunning: false, applePayError: true });
                            }
                        }
                        else {
                            this.setState({ rulesRunning: false, applePayError: true });
                        }
                        return status;
                    });
                    return status;
                }
                console.log(fetchStatus, status);
                const responseStatus = await fetchStatus();
                console.log(responseStatus);
                const complete = await response.complete(responseStatus);

                // Run rules and send payment information to chase (storing token or collecting deductible, accordingly), then
                // run TransUnion calls and CreateRepair.
                //console.log("Status: ", status);
                // status should be complete when the Chase call is successful, or failure if it fails.
                // need to handle failure
            }
            catch (ex) {
                console.log("Inner exception: ", ex);
                this.setState({ rulesRunning: false, applePayError: true });
            }
        } catch (e) {
            // Handle errors
            console.log(e);
            this.setState({ rulesRunning: false, applePayError: true });
        }
        //let aPSession = new ApplePaySession(3, applePayRequest);
        //aPSession.begin();
    }

    handleCheckAddress = e => {
        const { checked } = e.target;
        let state = this.props.entitlementDetails.state;
        let states = this.state.states;
        let address = {
            address1: '',
            address2: '',
            city: '',
            state: '',
            stateId: null,
            zip: '',
            countryCode: this.props.entitlementDetails?.countryCode
        };
        
        this.setState({
            customAddress: !checked,
            billingAddress: checked ? Object.assign({}, this.state.verifiedAddress) : address
        });            
    }

    componentDidMount() {
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', this.onBackButtonEvent);
        window.addEventListener('beforeunload', this.keepOnPage);
    }

    onBackButtonEvent = (e) => {
        e.preventDefault();
        if (!this.state.onClickBackButton) {
            this.state.onClickBackButton = true;
            this.props.history.push('/vendor/apple/verifyinfo', {
                entitlementDetails: this.props.entitlementDetails, claim: this.props.claim
            });
        }
        else {
            window.history.pushState(null, null, window.location.pathname);
            this.state.onClickBackButton = false;
        }
    }

    componentWillUnmount() {
        window.removeEventListener('popstate', this.onBackButtonEvent);
        window.removeEventListener('beforeunload', this.keepOnPage);
    }

    saveForLater = (e) => {
        e && e.preventDefault();
        this.setState({ isSaveForLater: true }, () => { this.saveForLaterAction(this.props.history.location.state.claim, false) });
    }

    saveForLaterAction = (claim, claimAbandoned) => {
        if (!claim) { return; }
        this.setState({ uploading: true });
        console.log(this.props);
        fetch(`api/claim/SaveForLater/${claim.claimId}/apple/3/${claimAbandoned}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(claim)
        })
            .then(data => {
                if (data.status === 204) {
                }
                if (data.status === 200 && !claimAbandoned) {
                    this.props.history.push('/vendor/apple/confirmation', { statusType: "savedClaim", caseNumber: claim.clientClaimNumber });
                }
                this.setState({ uploading: false });
            });
    }

    keepOnPage(e) {
        // Cancel the event as stated by the standard.
        e.preventDefault();
        // Chrome requires returnValue to be set.
        e.returnValue = '';
        if (!this.state.isCancelClicked) {            
            alert("Closing");
            this.saveForLaterAction(this.props.history.location.state.claim, true);
        }
    }

    pullLocationInfoFromZip = (zip) => {
        if (zip.length >= 5) {
            getLocationFromZip(zip, this.state.clientId, this.onZipVerified);
        }
    }

    onZipVerified = (result) => {
        if (result.successful) {
            let state = this.state.states.find(({ abbreviation }) => abbreviation === result.location.state)?.data;
            if (!state) return;

            let billingAddress = this.state.billingAddress;
            billingAddress.stateId = state;
            billingAddress.city = result.location.city;
            this.setState({
                billingAddress: billingAddress
            });
        }
    }

    handleChangeBillingCountry = (states, billingAddressCountry) => {
        this.setState({
            billingAddress: {
                state: '',
                stateId: null,
                countryCode: billingAddressCountry
            },
            billingStates: states
        })
    }

    handleInputChange = e => {
        let { name, value } = e.target;
        let fieldName = name;
        let errors = this.state.errors;
        let billingAddress = this.state.billingAddress;
        //let messages = this.validator.getErrorMessages();
        let validations = "required";

        if (name === "ccNumber") {
            value = e.target.value;
        }
        if (name === "cardExpires") {
            value = e.target.rawValue;
        }

        if (name === "zipCode") {
            this.pullLocationInfoFromZip(value);
        }
        switch (name) {
            case "ccNumber":
                errors["ccInvalid"] = false;
                fieldName = "creditCardNumber";
                validations = 
                this.props.entitlementDetails?.countryCode?.toLowerCase() === "us" 
                    ? "required|card_num" 
                    : "required|card_num|card_notsupported";
                break;
            case "cardExpires":
                errors["ccExpired"] = false;
                fieldName = "expirationDate";
                validations = "required|card_expires";
                break;
            case "securityCode":
                fieldName = "securityCode"
                validations = "required|cvv";
                break;
            case "address1":
                fieldName = "address1";
                billingAddress["address1"] = value;
                validations = this.state.selectedAddress == "billingAddress" ? "required" : "";
                break;
            case "address2":
                billingAddress["address2"] = value;
                validations = this.state.selectedAddress == "billingAddress" ? "required" : "";
                break;
            case "city":
                billingAddress[name] = value;
                validations = this.state.selectedAddress == "billingAddress" ? "required" : "";
                break;
            case "state":
                validations = this.state.selectedAddress == "billingAddress" ? "required" : "";
                break;
            case "zip":
                fieldName = "Zip/Postal code"
                billingAddress[name] = value;
                validations = this.state.selectedAddress == "billingAddress" ? "required" : "";
                break;
            default:
                break;
        }

        errors[name] = !this.validator.check(value, validations);

        this.setState({
            [name]: value,
            billingAddress: billingAddress,
            errors: errors
        });

        //console.log(this.state);

    }

    ccTypeChanged(type) {
        switch (type) {
            case "visa":
                break;
            case "mastercard":
                break;
            case "amex":
                break;
            case "discover":
                break;
            default:
                type = "unknown"
                break;
        };

        this.setState(() => ({
            ccType: type
        }));
    }

    applePay = (paymentRequest, paymentResponse) => {
        let currentMonth = new Date().getMonth().toString();
        let currentYear = new Date().getFullYear().toString().substring(2, 4);
        let month = this.state.cardExpires.substring(0, 2);
        let year = this.state.cardExpires.substring(2, 4);
        let address = this.props.entitlementDetails?.party?.shippingAddress ?? this.props.entitlementDetails?.party?.serviceAddress;;
        let alternateAddress = this.props.entitlementDetails?.party?.billingAddress ?? this.props.entitlementDetails?.party?.serviceAddress;;
        let statusCode = '';
        let valid = false;
        let claimNumber = typeof this.state.claimNumber === "undefined" ? "" : this.state.claimNumber.toString();
        let assetConfig = this.props.entitlementDetails.contractAsset.assetConfigDictionary;
        let makeAndModelNumber = typeof assetConfig === 'object' && assetConfig.hasOwnProperty(2) ? assetConfig[2] : "";
        let applePayResponse = "fail";
        let countryCode = this.props.entitlementDetails?.countryCode ?? "us";

        let ccTypeId = this.state.ccTypeId;
        switch (this.state.ccType) {
            case "visa":
                ccTypeId = "1";
                break;
            case "amex":
                ccTypeId = "2";
                break;
            case "mastercard":
                ccTypeId = "4";
                break;
            case "discover":
                ccTypeId = "3";
                break;
            default:
                break;
        }

        let countryISO3 = "USA";

        switch (countryCode.toLowerCase()) {
            case "de":
                countryISO3 = "DEU";
                break;
            case "aus":
                countryISO3 = "AUS";
                break;
            case "uk":
                countryISO3 = "GBR";
                break;
            case "fr":
                countryISO3 = "FRA";
                break;
            case "it":
                countryISO3 = "ITA";
                break;
            case "es":
                countryISO3 = "ESP";
                break;
            case "vn":
                countryISO3 = "VNM";
                break;
            case "cl":
                countryISO3 = "CHL";
                break;
            case "at":
                countryISO3 = "ESP";
                break;
            case "dk":
                countryISO3 = "DNK";
                break;
            case "fi":
                countryISO3 = "FIN";
                break;
            case "ie":
                countryISO3 = "IRL";
                break;
            case "nl":
                countryISO3 = "NLD";
                break;
            case "nz":
                countryISO3 = "NZL";
                break;
            case "no":
                countryISO3 = "NOR";
                break;
            case "se":
                countryISO3 = "SWE";
                break;
            case "ch":
                countryISO3 = "CHE";
                break;
            default:
                countryISO3 = "USA";
                break;
        }

        let amount = 0;
        let failureReason = "";
        let eligible = false;
        let fraud = false;
        let fmip = false;
        let capture = false;

        let response = null;

        let transUnionResult = 0;
        let token = "";
        let caseId = "";
        let transUnionDecision = null;
        var storedSession = typeof window.TrustevV2 == "undefined" ? "" : window.TrustevV2.SessionId;
        sessionId = (storedSession == "" || typeof storedSession == "undefined") ? "" : storedSession;

        let transUnionCaseModel = {
            PartyId: this.props.entitlementDetails.party.partyId,
            Addresses: [
                {
                    Address1: address.address1,
                    Address2: address.address2,
                    City: address.city,
                    CountryCode: countryCode?.toUpperCase(),
                    FirstName: this.props.entitlementDetails.party.firstName,
                    LastName: this.props.entitlementDetails.party.lastName,
                    PostalCode: address.zip?.substring(0, 5),
                    State: this.state.states.find(state => { return state.data == address.stateId })?.abbreviation ?? null,
                    AddressType: 1
                },
                {
                    Address1: alternateAddress.address1,
                    Address2: alternateAddress.address2,
                    City: alternateAddress.city,
                    CountryCode: countryCode?.toUpperCase(),
                    FirstName: this.props.entitlementDetails.party.firstName,
                    LastName: this.props.entitlementDetails.party.lastName,
                    PostalCode: alternateAddress.zip?.substring(0, 5),
                    State: this.state.states.find(state => { return state.data == alternateAddress.stateId })?.abbreviation ?? null,
                    AddressType: 2
                }
            ],
            Phone: this.props.entitlementDetails.party.partyPhoneModel[0].number,
            EmailAddress: this.props.entitlementDetails.email,
            FirstName: this.props.entitlementDetails.party.firstName,
            LastName: this.props.entitlementDetails.party.lastName,
            ClaimNumber: this.state.claimNumber,
            ClaimId: parseInt(this.props.entitlementDetails.claimId),
            Token: "", // TBD
            SessionId: sessionId,
            ClientId: this.state.clientId
        };

        let iDologyRequestModel = {
            firstName: this.props.entitlementDetails.party.firstName,
            lastName: this.props.entitlementDetails.party.lastName,
            phone: this.props.entitlementDetails.party.partyPhoneModel[0].number,
            emailAddress: this.props.entitlementDetails.email,
            mailingAddress:
            {
                address1: address.address1,
                address2: address.address2,
                city: address.city,
                zip: address.zip,
                state: this.state.shipToStates.find(state => { return state.data == address.stateId })?.abbreviation ?? null,
                stateName: this.state.shipToStates.find(state => { return state.data == address.stateId })?.display ?? null
            },
            alternateAddress: {
                address1: alternateAddress.address1,
                address2: alternateAddress.address2,
                city: alternateAddress.city,
                zip: alternateAddress.zip,
                state: this.state.states.find(state => { return state.data == alternateAddress.stateId })?.abbreviation ?? null,
                stateName: this.state.states.find(state => { return state.data == alternateAddress.stateId })?.display ?? null
            },
            claimNumber: this.state.claimNumber,
            claimId: parseInt(this.props.entitlementDetails.claimId),
            countryCode: countryISO3
        };

        try {
            fetch('api/rulesEngine/ExecuteRulesAndTransUnion/'.concat(this.state.clientId), {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    addCaseModel: transUnionCaseModel,
                    iDologyRequest: iDologyRequestModel,
                    claimId: this.props.entitlementDetails.claimId?.toString(),
                    countryCode: countryCode,
                    customerVerificationResult: this.state.customerVerificationResult?.toString(),
                    rulesExecutionModel: {
                        rulesEngineExecutionModel: {
                            clientId: this.state.clientId,
                            contractId: parseInt(this.props.entitlementDetails.contract.contractId),
                            claimId: parseInt(this.props.entitlementDetails.claimId)
                        },
                        rules: [1, 2, 3]
                    }
                })
            }).then(res => {
                if (res.ok) {
                    res.json().then(rulesExecutionResponse => {
                        console.log(rulesExecutionResponse);
                        let rulesResult = rulesExecutionResponse.rulesExecutionResult;
                        transUnionDecision = rulesExecutionResponse.transUnionDecision;
                        eligible = rulesResult[0]?.success;
                        fraud = rulesResult[1]?.success;
                        fmip = (typeof rulesResult[2] == "undefined" || rulesResult[2] == null) ? true : rulesResult[2].success;

                        token = transUnionDecision?.tokenId;
                        sessionId = transUnionDecision?.sessionId;
                        caseId = transUnionDecision?.caseId;
                        this.setState({ customerVerificationResult: transUnionDecision?.result ?? "0" });
                        transUnionResult = transUnionDecision?.result ?? 0;

                        if (sessionId == "") {
                            let errors = this.state.errors;
                            errors["internal"] = true;
                            this.setState({ searching: false, errors: errors }, () => {
                                // Show error messages
                                console.log(statusCode);
                                //return applePayResponse;
                            });
                        }

                        if (eligible && fraud && fmip && transUnionResult == 1) {
                            //if (eligible && fraud && fmip) {
                            console.log("Passed");
                            capture = true;
                            amount = this.state.amount;
                        }
                        else {
                            console.log("Didn't pass");
                            capture = false;
                            amount = this.state.amount;
                        }

                        //update partner case status to CSDP after payment is successful
                        updatePartnerCase(this.state.claimNumber, "CSDP", this.state.clientId);
                        // update authentication error flag to "0" - false
                        if (this.state.authenticationErrorFlag) {
                            createOrUpdateClaimConfiguration(this.props.entitlementDetails?.claimId ?? 0, 36, "0", this.state.clientId);
                        }

                        if (capture) {
                            // call payment service
                            fetch('api/payment/applePayCapture/'.concat(this.state.clientId), {
                                method: 'post',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    orderID: String(this.props.entitlementDetails.claimId),
                                    orderNumber: this.state.claimNumber,
                                    amount: this.state.amount,
                                    txRefNum: this.state.txRefNum,
                                    countryCode: countryCode
                                })
                            }).then(function (res) {

                                console.log(res);
                                applePayResponse = "fail";
                                statusCode = res.status;
                                return res.json();
                            }).then(data => {
                                response = data;
                                console.log(data);
                                if (statusCode === 204) {
                                    // Failed charge
                                    // TransUnion Call
                                    addTUStatus(this.state.claimNumber, token, caseId, 2, this.state.clientId);
                                    this.setState({ searching: false }, () => {
                                        applePayResponse = "fail";
                                        console.log(applePayResponse);
                                        this.setState({ rulesRunning: false, applePayError: true });
                                        //(async function () { await paymentResponse.complete(applePayResponse); })();
                                        return applePayResponse;
                                    });
                                }
                                else if (statusCode === 200) {
                                    if (data?.procStatus != "0") {
                                        // Failed charge
                                        applePayResponse = "fail";
                                        console.log(applePayResponse);
                                        this.setState({ rulesRunning: false, applePayError: false });
                                        //(async function () { await paymentResponse.complete(applePayResponse); })();
                                        return applePayResponse;
                                    }
                                    else {
                                        applePayResponse = "success";
                                        console.log(applePayResponse);
                                        //(async function () { await paymentResponse.complete(applePayResponse); })();
                                    }
                                    valid = true;
                                    // TransUnion Call
                                    if (transUnionResult === 1) {
                                        // Add Completed Status
                                        addTUStatus(this.state.claimNumber, token, caseId, 0, this.state.clientId);
                                    }

                                    if (!eligible) {
                                        failureReason = 'Eligibility Review'
                                        console.log('1:', failureReason)
                                    }
                                    // TransUnion Call
                                    if (!fraud || transUnionResult != 1) {
                                        //if (!fraud)
                                        failureReason = 'Investigation Review'
                                        console.log('2:', failureReason)
                                    }
                                    if (!fmip) {
                                        failureReason = 'Eligibility Review'
                                        console.log('3:', failureReason)
                                    }
                                    //Add to Agent Queue
                                    fetch('api/queue/queueitem/'.concat(this.state.clientId), {
                                        method: 'post',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({
                                            ClientId: this.state.clientId,
                                            ClaimNumber: this.props.entitlementDetails.claimNumber,
                                            ClaimId: parseInt(this.props.entitlementDetails.claimId),
                                            ContractId: parseInt(this.props.entitlementDetails.contract.contractId),
                                            IsEligible: eligible,
                                            IsFraud: fraud,
                                            IsFMiP: fmip,
                                            TransUnionResult: transUnionResult,
                                            FailureReason: failureReason
                                        })
                                    }).then(res => {
                                        if (res.ok) {

                                            // SRV - moving this lower while we wait for create repair
                                            //this.setState({ searching: false, paid: true });

                                            if (!eligible) {
                                                this.props.history.push('/vendor/apple/confirmation', {
                                                    statusType: "claimInReview",
                                                    caseNumber: this.props.entitlementDetails.caseNumber,
                                                    client: this.state.clientId,
                                                    contractNumber: this.props.entitlementDetails.contract.contractNumber,
                                                    claimNumber: this.state.claimNumber,
                                                    claimId: this.props.entitlementDetails.claimId
                                                }); // Failed rule
                                                console.log(applePayResponse);
                                                this.setState({ rulesRunning: false });
                                                //(async function () { await paymentResponse.complete(applePayResponse); })();
                                                return applePayResponse;
                                            }
                                            else if (!fraud) {

                                                this.props.history.push("/vendor/apple/uploaddocs/", { isFraud: true, entitlementDetails: this.props.entitlementDetails, statusType: "docsPending", clientId: this.state.clientId, claimNumber: this.state.claimNumber, caseNumber: this.props.entitlementDetails.caseNumber, claimId: this.props.entitlementDetails.claimId, ccNumber: this.state.ccNumber.substring(12, 16), ccType: this.state.ccType })

                                                let countryCode = this.props.entitlementDetails?.countryCode ?? "us";
                                                let languageCode = this.context.languageContext.language ?? "en";
                                                let documentRequired = "Government ID or Proof of Purchase";
                                                let emailType = 9;
                                                if (countryCode.toLowerCase() == "aus") {
                                                    emailType = 33;
                                                }
                                                else if (countryCode.toLowerCase() == "uk") {
                                                    emailType = 34;
                                                }
                                                else if (countryCode.toLowerCase() == "de" && languageCode == "en") {
                                                    emailType = 35;
                                                }
                                                else if (countryCode.toLowerCase() == "de" && languageCode == "de") {
                                                    emailType = 36;
                                                    documentRequired = "Personalausweis oder Kaufnachweis";
                                                }
                                                else if (countryCode.toLowerCase() == "fr" && languageCode.toLowerCase() == "fr") {
                                                    emailType = 133;
                                                    documentRequired = "Pièce d'identité gouvernementale ou preuve d'achat";
                                                }
                                                else if (countryCode.toLowerCase() == "it" && languageCode.toLowerCase() == "it") {
                                                    emailType = 134;
                                                    documentRequired = "ID governativo o prova di acquisto";
                                                }
                                                else if (countryCode.toLowerCase() == "es" && languageCode.toLowerCase() == "es") {
                                                    emailType = 135;
                                                    documentRequired = "Identificación del gobierno o prueba de compra";
                                                }
                                                else if (countryCode.toLowerCase() == "fr" && languageCode.toLowerCase() == "en") {
                                                    emailType = 175;
                                                }
                                                else if (countryCode.toLowerCase() == "it" && languageCode.toLowerCase() == "en") {
                                                    emailType = 176;
                                                }
                                                else if (countryCode.toLowerCase() == "es" && languageCode.toLowerCase() == "en") {
                                                    emailType = 177;
                                                }
                                                else {
                                                    emailType = 9;
                                                }

                                                var miniSiteURL = window.location.origin + '/vendor/apple/' + this.props.entitlementDetails.caseNumber + '/' + this.props.entitlementDetails.email;
                                                fetch('api/communication/'.concat(this.state.clientId), {
                                                    method: 'post',
                                                    headers: {
                                                        'Content-Type': 'application/json'
                                                    },
                                                    body: JSON.stringify({

                                                        emailModel: {

                                                            Recipients: [this.props.entitlementDetails.email],
                                                            Cc: [],
                                                            Bcc: [],
                                                            IsBodyHtml: true,
                                                            emailType: emailType
                                                        },
                                                        serviceModel: {
                                                            FirstName: this.state.firstName,
                                                            LastName: this.state.lastName,
                                                            MiniSiteURL: miniSiteURL,
                                                            WirelessNumber: countryCode.toLowerCase() == "us" ? this.props.entitlementDetails.contactPhone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3") : this.props.entitlementDetails.contactPhone,
                                                            DeviceMakeandModel: makeAndModelNumber,
                                                            CaseNumber: this.props.entitlementDetails.caseNumber,
                                                            CaseNumberDate: moment(this.props.entitlementDetails.incidentDate).format("MM/DD/YYYY"),
                                                            DocumentDeliveryMailAddress: "Claims Department, 650 Missouri Ave. Jeffersonville, IN 47130",
                                                            DocumentDeliveryEmailAddress: "AIGClaimsCenter@sndirect.com",
                                                            CallCenterTollFreeNo: this.state.callNumber,
                                                            ClaimNumber: this.props.entitlementDetails.caseNumber,
                                                            ClaimSetupDate: moment(this.props.entitlementDetails.claimSetupDate).format("MM/DD/YYYY"),
                                                            SerialNumber: this.props.entitlementDetails.contractAsset.serialNumber,
                                                            DocumentRequired: documentRequired, //this.props.DocumentRequired
                                                            ClaimId: parseInt(this.props.entitlementDetails.claimId),
                                                            WarrantyObjectTypeId: 2
                                                        }
                                                    })
                                                }).then(function (res) {
                                                    console.log(res);
                                                    statusCode = res.status;
                                                    return res.json();
                                                })
                                                console.log(applePayResponse);
                                                this.setState({ rulesRunning: false });
                                                //(async function () { await paymentResponse.complete(applePayResponse); })();
                                                return applePayResponse;
                                            }
                                            else if (!fmip) {
                                                this.props.history.push('/vendor/apple/confirmation', {
                                                    statusType: "claimInReview",
                                                    caseNumber: this.props.entitlementDetails.caseNumber,
                                                    client: this.state.clientId,
                                                    contractNumber: this.props.entitlementDetails.contract.contractNumber,
                                                    claimNumber: this.state.claimNumber,
                                                    claimId: this.props.entitlementDetails.claimId
                                                }); // Failed rule
                                                console.log(applePayResponse);
                                                this.setState({ rulesRunning: false });
                                                //(async function () { await paymentResponse.complete(applePayResponse); })();
                                                return applePayResponse;
                                            }
                                            else {
                                                createNewAuthorization(this.props.entitlementDetails.claimId, billingCodeDisplayIds.unitReplacementFullBuyOut,
                                                    authorizationStatus.approved, this.state.clientId)
                                                    .then(() => {
                                                        // update repair
                                                        updateRepair(this.state.clientId, this.state.claimNumber, "CRCP") // SEU-1548, change to CRCP
                                                            .then(res => {
                                                                console.log(res);

                                                                                                    // create repair at apple
                                                                                                    createRepair(this.state.clientId, this.state.claimNumber)
                                                                                                        .then(res => {
                                                                                                            if (res.ok) {
                                                                                                                //update partner case
                                                                                                                updatePartnerCase(this.state.claimNumber, "CRCN", this.state.clientId);
                                                                                                                let countryCode = this.props.entitlementDetails?.countryCode ?? "us";
                                                                                                                let languageCode = this.context.languageContext.language ?? "en";
                                                                                                                let nonUSMiniSiteURL = window.location.origin + '/vendor/apple/' + this.props.entitlementDetails.caseNumber + '/' + this.props.entitlementDetails.email;
                                                                                                                let miniSiteURL = countryCode.toLowerCase() == "us" ? '<a href="https://www.aigtheftandloss.com">www.aigtheftandloss.com</a>' : nonUSMiniSiteURL;
                                                                                                                let emailType = 7;
                                                                                                                if (countryCode.toLowerCase() == "aus") {
                                                                                                                    emailType = 76;
                                                                                                                }
                                                                                                                else if (countryCode.toLowerCase() == "uk") {
                                                                                                                    emailType = 77;
                                                                                                                }
                                                                                                                else if (countryCode.toLowerCase() == "de" && languageCode.toLowerCase() == "de") {
                                                                                                                    emailType = 78;
                                                                                                                }
                                                                                                                else if (countryCode.toLowerCase() == "de" && languageCode.toLowerCase() == "en") {
                                                                                                                    emailType = 79;
                                                                                                                }
                                                                                                                else if (countryCode.toLowerCase() == "fr" && languageCode.toLowerCase() == "fr") {
                                                                                                                    emailType = 124;
                                                                                                                }
                                                                                                                else if (countryCode.toLowerCase() == "fr" && languageCode.toLowerCase() == "en") {
                                                                                                                    emailType = 166;
                                                                                                                }
                                                                                                                else if (countryCode.toLowerCase() == "it" && languageCode.toLowerCase() == "it") {
                                                                                                                    emailType = 125;
                                                                                                                }
                                                                                                                else if (countryCode.toLowerCase() == "it" && languageCode.toLowerCase() == "en") {
                                                                                                                    emailType = 167;
                                                                                                                }
                                                                                                                else if (countryCode.toLowerCase() == "es" && languageCode.toLowerCase() == "es") {
                                                                                                                    emailType = 126;
                                                                                                                }
                                                                                                                else if (countryCode.toLowerCase() == "es" && languageCode.toLowerCase() == "en") {
                                                                                                                    emailType = 168;
                                                                                                                }
                                                                                                                else {
                                                                                                                    emailType = 7;
                                                                                                                }

                                                                            // Send Approved Mail
                                                                            fetch('api/communication/'.concat(this.state.clientId), {
                                                                                method: 'post',
                                                                                headers: {
                                                                                    'Content-Type': 'application/json'
                                                                                },
                                                                                body: JSON.stringify({

                                                                                    emailModel: {

                                                                                        Recipients: [this.props.entitlementDetails.email],
                                                                                        Cc: [],
                                                                                        Bcc: [],
                                                                                        IsBodyHtml: true,
                                                                                        emailType: emailType
                                                                                    },
                                                                                    serviceModel: {
                                                                                        SubscriberName: this.state.firstName + ' ' + this.state.lastName,
                                                                                        firstName: this.state.firstName,
                                                                                        lastName: this.state.lastName,
                                                                                        MiniSiteURL: miniSiteURL,
                                                                                        WirelessNumber: this.props.entitlementDetails.contactPhone,
                                                                                        DeviceMakeandModel: makeAndModelNumber,
                                                                                        SerialNumber: this.props.entitlementDetails.contractAsset?.serialNumber,
                                                                                        CaseNumber: this.props.entitlementDetails.caseNumber,
                                                                                        CaseNumberDate: moment(this.props.entitlementDetails.incidentDate).format("MM/DD/YYYY"),
                                                                                        ClaimId: parseInt(this.props.entitlementDetails.claimId),
                                                                                        WarrantyObjectTypeId: 2
                                                                                    }
                                                                                })
                                                                            }).then(function (res) {
                                                                                console.log(res);
                                                                                statusCode = res.status;
                                                                                return res.json();
                                                                            })

                                                                            // navigate to successful confirmation
                                                                            this.props.history.push('/vendor/apple/confirmation', {
                                                                                statusType: "approvedClaim",
                                                                                caseNumber: this.props.entitlementDetails.caseNumber,
                                                                                client: this.state.clientId,
                                                                                contractNumber: this.props.entitlementDetails.contract.contractNumber,
                                                                                claimNumber: this.state.claimNumber,
                                                                                claimId: this.props.entitlementDetails.claimId
                                                                            }); // Successful payment
                                                                            console.log(applePayResponse);
                                                                            this.setState({ rulesRunning: false });
                                                                            //(async function () { await paymentResponse.complete(applePayResponse); })();
                                                                            return applePayResponse;
                                                                        }
                                                                        else {
                                                                            // create repair failed
                                                                            console.log('create repair failed - move to review');
                                                                            this.props.history.push('/vendor/apple/confirmation', {
                                                                                statusType: "claimInReview",
                                                                                caseNumber: this.props.entitlementDetails.caseNumber,
                                                                                client: this.state.clientId,
                                                                                contractNumber: this.props.entitlementDetails.contract.contractNumber,
                                                                                claimNumber: this.state.claimNumber,
                                                                                claimId: this.props.entitlementDetails.claimId
                                                                            }); // Failed rule
                                                                            console.log(applePayResponse);
                                                                            this.setState({ rulesRunning: false });
                                                                            //(async function () { await paymentResponse.complete(applePayResponse); })();
                                                                            return applePayResponse;
                                                                        }

                                                                        this.setState({ searching: false, paid: true });
                                                                        console.log(applePayResponse);
                                                                        this.setState({ rulesRunning: false });
                                                                        //(async function () { await paymentResponse.complete(applePayResponse); })();
                                                                        return applePayResponse;
                                                                    });
                                                            });
                                                    });
                                            }
                                        }
                                        else {

                                            this.setState({ searching: false, rulesRunning: false }, () => {
                                                console.log(applePayResponse);
                                                //(async function () { await paymentResponse.complete(applePayResponse); })();
                                            });
                                            return applePayResponse;
                                        }
                                    });
                                }

                                else {

                                    this.setState({ searching: false, rulesRunning: false }, () => {
                                        console.log(applePayResponse);
                                        //(async function () { await paymentResponse.complete(applePayResponse); })();
                                    });
                                    return applePayResponse;
                                }
                            })

                        }
                        else {
                            if (!eligible) {
                                failureReason = 'Eligibility Review'
                                console.log('1:', failureReason)
                            }
                            // TransUnion Call
                            if (!fraud || transUnionResult != 1) {
                                //if (!fraud)
                                failureReason = 'Investigation Review'
                                console.log('2:', failureReason)
                            }
                            if (!fmip) {
                                failureReason = 'Eligibility Review'
                                console.log('3:', failureReason)
                            }
                            //Add to Agent Queue
                            fetch('api/queue/queueitem/'.concat(this.state.clientId), {
                                method: 'post',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    ClientId: this.state.clientId,
                                    ClaimNumber: this.props.entitlementDetails.claimNumber,
                                    ClaimId: parseInt(this.props.entitlementDetails.claimId),
                                    ContractId: parseInt(this.props.entitlementDetails.contract.contractId),
                                    IsEligible: eligible,
                                    IsFraud: fraud,
                                    IsFMiP: fmip,
                                    TransUnionResult: transUnionResult,
                                    FailureReason: failureReason
                                })
                            }).then(res => {
                                if (res.ok) {

                                    // SRV - moving this lower while we wait for create repair
                                    //this.setState({ searching: false, paid: true });

                                    if (!fraud || transUnionResult != 1) {

                                        this.props.history.push("/vendor/apple/uploaddocs/", { isFraud: true, entitlementDetails: this.props.entitlementDetails, statusType: "docsPending", clientId: this.state.clientId, claimNumber: this.state.claimNumber, caseNumber: this.props.entitlementDetails.caseNumber, claimId: this.props.entitlementDetails.claimId, ccNumber: this.state.ccNumber.substring(12, 16), ccType: this.state.ccType })

                                        let countryCode = this.props.entitlementDetails?.countryCode ?? "us";
                                        let languageCode = this.context.languageContext.language ?? "en";
                                        let documentRequired = "Government ID or Proof of Purchase";
                                        let emailType = 9;
                                        if (countryCode.toLowerCase() == "aus") {
                                            emailType = 33;
                                        }
                                        else if (countryCode.toLowerCase() == "uk") {
                                            emailType = 34;
                                        }
                                        else if (countryCode.toLowerCase() == "de" && languageCode.toLowerCase() == "en") {
                                            emailType = 35;
                                        }
                                        else if (countryCode.toLowerCase() == "de" && languageCode.toLowerCase() == "de") {
                                            emailType = 36;
                                            documentRequired = "Personalausweis oder Kaufnachweis";
                                        }
                                        else {
                                            emailType = 9;
                                        }
                                        var miniSiteURL = window.location.origin + '/vendor/apple/' + this.props.entitlementDetails.caseNumber + '/' + this.props.entitlementDetails.email ;
                                        fetch('api/communication/'.concat(this.state.clientId), {
                                            method: 'post',
                                            headers: {
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify({

                                                emailModel: {

                                                    Recipients: [this.props.entitlementDetails.email],
                                                    Cc: [],
                                                    Bcc: [],
                                                    IsBodyHtml: true,
                                                    emailType: emailType
                                                },
                                                serviceModel: {
                                                    FirstName: this.state.firstName,
                                                    LastName: this.state.lastName,
                                                    MiniSiteURL: miniSiteURL,
                                                    WirelessNumber: countryCode.toLowerCase() == "us" ? this.props.entitlementDetails.contactPhone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3") : this.props.entitlementDetails.contactPhone,
                                                    DeviceMakeandModel: makeAndModelNumber,
                                                    CaseNumber: this.props.entitlementDetails.caseNumber,
                                                    CaseNumberDate: moment(this.props.entitlementDetails.incidentDate).format("MM/DD/YYYY"),
                                                    DocumentDeliveryMailAddress: "Claims Department, 650 Missouri Ave. Jeffersonville, IN 47130",
                                                    DocumentDeliveryEmailAddress: "AIGClaimsCenter@sndirect.com",
                                                    CallCenterTollFreeNo: this.state.callNumber,
                                                    ClaimNumber: this.props.entitlementDetails.caseNumber,
                                                    ClaimSetupDate: moment(this.props.entitlementDetails.claimSetupDate).format("MM/DD/YYYY"),
                                                    SerialNumber: this.props.entitlementDetails.contractAsset.serialNumber,
                                                    DocumentRequired: documentRequired, //this.props.DocumentRequired
                                                    ClaimId: parseInt(this.props.entitlementDetails.claimId),
                                                    WarrantyObjectTypeId: 2
                                                }
                                            })
                                        }).then(function (res) {
                                            console.log(res);
                                            statusCode = res.status;
                                            return res.json();
                                        })
                                        console.log(applePayResponse);
                                        this.setState({ rulesRunning: false });
                                        //(async function () { await paymentResponse.complete(applePayResponse); })();
                                        return applePayResponse;
                                    }
                                    else if (!eligible) {
                                        this.props.history.push('/vendor/apple/confirmation', {
                                            statusType: "claimInReview",
                                            caseNumber: this.props.entitlementDetails.caseNumber,
                                            client: this.state.clientId,
                                            contractNumber: this.props.entitlementDetails.contract.contractNumber,
                                            claimNumber: this.state.claimNumber,
                                            claimId: this.props.entitlementDetails.claimId
                                        }); // Failed rule
                                        console.log(applePayResponse);
                                        this.setState({ rulesRunning: false });
                                        //(async function () { await paymentResponse.complete(applePayResponse); })();
                                        return applePayResponse;
                                    }
                                    else if (!fmip) {
                                        this.props.history.push('/vendor/apple/confirmation', {
                                            statusType: "claimInReview",
                                            caseNumber: this.props.entitlementDetails.caseNumber,
                                            client: this.state.clientId,
                                            contractNumber: this.props.entitlementDetails.contract.contractNumber,
                                            claimNumber: this.state.claimNumber,
                                            claimId: this.props.entitlementDetails.claimId
                                        }); // Failed rule
                                        console.log(applePayResponse);
                                        this.setState({ rulesRunning: false });
                                        //(async function () { await paymentResponse.complete(applePayResponse); })();
                                        return applePayResponse;
                                    }
                                }
                            })
                        }
                    }); // Rule execution end
                }
                else {

                    this.setState({ searching: false, rulesRunning: false }, () => {
                        console.log(applePayResponse);
                        //(async function () { await paymentResponse.complete(applePayResponse); })();
                    });
                    return applePayResponse;
                }
            })
        }
        catch (e) {
            console.log('Apple pay exception', e);
            this.setState({ searching: false, rulesRunning: false }, () => {
                console.log(applePayResponse);
                return applePayResponse;
            });
        }
    }

    getErrorStatus = (errorCode) => {
        const country = this.props.entitlementDetails?.countryCode ?? 'us';
       
        switch (errorCode) {
            case 'SN-CHASE-AUTHENTICATION-FAILED':
                this.setState({
                    authenticationErrorMessage: 'Unfortunately, there was an issue authenticating your payment. Please try paying with a different credit card',
                    payErrorContact: null,
                    failedAuthPayments: this.state.failedAuthPayments + 1
                });
                break;
            case 'SN-CHASE-AUTHENTICATION-PENDING':
                this.setState({
                    authenticationErrorMessage: `Unfortunately, there was an issue authenticating your payment. If you'd like to speak with one of our representatives, call`,
                    payErrorContact: appleServiceNumbers?.customerService[country?.toUpperCase()],
                    authenticationPhoneNumber: appleServiceNumbers?.customerService[country?.toUpperCase()],
                    failedAuthPayments: this.state.failedAuthPayments + 1
                });
                break;
        }
    }

    pay = e => {

        e.preventDefault();
        this.setState({ validationFired: true, isProcessing: true });
        const billingCountryName = {
            de: "Germany",
            aus: "Australia",
            uk: "UK",
            fr: "France",
            it: "Italy",
            es: "Spain",
            vn: "Vietnam",
            cl: "Chile",
            at: "Austria",
            dk: "Denmark",
            fi: "Finland",
            ie: "Ireland",
            nl: "Netherlands",
            nz: "New Zealand",
            no: "Norway",
            se: "Sweden",
            ch: "Switzerland",
            us: "USA"
        }
        let claimNumber = typeof this.state.claimNumber === "undefined" ? "" : this.state.claimNumber.toString();
        let assetConfig = this.props.entitlementDetails.contractAsset.assetConfigDictionary;
        let makeAndModelNumber = typeof assetConfig === 'object' && assetConfig.hasOwnProperty(2) ? assetConfig[2] : "";
        this.getApprovalStatus();

        if (this.validator.allValid()) {
            // Clear previous errors
            let errors = this.state.errors;
            for (var key of Object.keys(errors)) {
                errors[key] = false;
            }

            this.setState({ errors: errors });

            // Sets address for payment call
            let address = this.state.customAddress ? this.state.billingAddress : this.state.verifiedAddress;
            let alternateAddress = this.state.customAddress ? this.state.billingAddress : this.state.verifiedAddress;


            if (this.state.customAddress) {
                // save new address as billing address
                let countryId = this.state.activeCountries?.find(({ countryCode }) => countryCode?.toLowerCase() === this.state.billingAddress.countryCode?.toLowerCase())?.countryId;
                fetch('api/party/partyaddress/'
                    .concat(this.state.clientId),
                    {
                        method: 'put',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(
                            {
                                partyId: this.props.entitlementDetails.contract.party.partyId,
                                addressTypeId: 1,
                                address1: this.state.billingAddress.address1,
                                address2: this.state.billingAddress.address2,
                                city: this.state.billingAddress.city,
                                state: this.state.billingAddress.state,
                                stateId: this.state.billingAddress.stateId,
                                zip: this.state.billingAddress.zip,
                                countryId: countryId
                            }
                        )
                    }).then(res => {
                        if (res.ok) {
                            if (res.status === "204") {
                                res.json().then(data => {
                                    console.log(data);
                                });
                            }
                        }
                        else {
                            console.log(res);
                        }
                    })
            }

            // Sets credit card type
            let ccTypeId = this.state.ccTypeId;
            switch (this.state.ccType) {
                case "visa":
                    ccTypeId = "1";
                    break;
                case "amex":
                    ccTypeId = "2";
                    break;
                case "mastercard":
                    ccTypeId = "4";
                    break;
                case "discover":
                    ccTypeId = "3";
                    break;
                default:
                    break;
            }

            // Get month and year from expiration date
            let currentMonth = parseInt((new Date().getMonth() + 1).toString());
            let currentYear = parseInt(new Date().getFullYear().toString().substring(2, 4));
            let month = parseInt(this.state.cardExpires.substring(0, 2));
            let year = parseInt(this.state.cardExpires.substring(2, 4));

            if (year < currentYear) {
                errors["ccExpired"] = true;
                this.setState({ errors: errors });
                return;
            }
            if (year === currentYear) {
                if (month < currentMonth) {
                    errors["ccExpired"] = true;
                    this.setState({ errors: errors });
                    return;
                }
            }

            let statusCode = '';
            let valid = false;

            // keep spinner active while fetching
            this.setState({
                searching: true,
                paymentAddress: address,
                ccTypeId: ccTypeId
            });

            let countryCode = this.props.entitlementDetails?.countryCode ?? "us";
            let shipToCountryCode = this.props.entitlementDetails?.shipToCountryCode ?? countryCode;
            let threeLetterCode = "USA";
            let currency = "USD";
            let culture = "en-US";
            let countryName = "USA";

            switch (countryCode.toLowerCase()) {
                case "de":
                    threeLetterCode = "DEU";
                    currency = "EUR";
                    culture = "en-DE";
                    countryName = "Germany";
                    break;
                case "aus":
                    threeLetterCode = "AUS";
                    currency = "AUD";
                    culture = "en-AU";
                    countryName = "Australia";
                    break;
                case "uk":
                    threeLetterCode = "GBR";
                    currency = "GBP";
                    culture = "en-GB";
                    countryName = "UK";
                    break;
                case "fr":
                    threeLetterCode = "FRA";
                    currency = "EUR";
                    culture = "en-FR";
                    countryName = "France";
                    break;
                case "it":
                    threeLetterCode = "ITA";
                    currency = "EUR";
                    culture = "en-IT";
                    countryName = "Italy"
                    break;
                case "es":
                    threeLetterCode = "ESP";
                    currency = "EUR";
                    culture = "en-ES";
                    countryName = "Spain";
                    break;
                case "vn":
                    threeLetterCode = "VNM";
                    currency = "VND";
                    culture = "en-VN";
                    countryName = "Vietnam";
                    break;
                case "cl":
                    threeLetterCode = "CHL";
                    currency = "CLP";
                    culture = "en-CL";
                    countryName = "Chile";
                    break;
                case "at":
                    threeLetterCode = "AUT";
                    currency = "EUR";
                    culture = "en-AT";
                    countryName = "Austria";
                    break;
                case "dk":
                    threeLetterCode = "DNK";
                    currency = "DKK";
                    culture = "en-dk";
                    countryName = "Denmark";
                    break;
                case "fi":
                    threeLetterCode = "FIN";
                    currency = "EUR";
                    culture = "en-FI";
                    countryName = "Finland";
                    break;
                case "ie":
                    threeLetterCode = "IRL";
                    currency = "EUR";
                    culture = "en-ie";
                    countryName = "Ireland";
                    break;
                case "nl":
                    threeLetterCode = "NLD";
                    currency = "EUR";
                    culture = "en-NL";
                    countryName = "Netherlands";
                    break;
                case "nz":
                    threeLetterCode = "NZL";
                    currency = "NZD";
                    culture = "en-nz";
                    countryName = "New Zealand";
                    break;
                case "no":
                    threeLetterCode = "NOR";
                    currency = "NOK";
                    culture = "en-no";
                    countryName = "Norway";
                    break;
                case "se":
                    threeLetterCode = "SWE";
                    currency = "SEK";
                    culture = "en-SE";
                    countryName = "Sweden";
                    break;
                case "ch":
                    threeLetterCode = "CHE";
                    currency = "CHF";
                    culture = "en-ch";
                    countryName = "Switzerland";
                    break;
                default:
                    threeLetterCode = "USA";
                    currency = "USD";
                    culture = "en-US";
                    countryName = "USA";
                    break;
            }
            let isAuthenticationSuccessful = true;
            let authenticationSessionId = null;
            let authenticationTransactionId = null;
            let shippingAddress = this.props.entitlementDetails?.party?.shippingAddress

            fetch('api/payment/initiateAuthentication/'.concat(this.state.clientId), {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Amount: parseFloat(this.props.entitlementDetails.deductible),
                    CardHolder: {
                        FirstName: this.state.firstName,
                        LastName: this.state.lastName,
                        SelectedAddress: this.state.selectedAddress,
                        BillingAddress: {
                            StreetAddress: address?.address1,
                            Locality: address?.city,
                            Region: (address?.countryCode?.toLowerCase() == "de" || address?.countryCode?.toLowerCase() == "fr") ? billingCountryName[address?.countryCode?.toLowerCase()] : (this.state.billingStates?.find(state => { return state.data == address.stateId })?.display.split(' ')[0]?.length > 1 ? this.state.billingStates?.find(state => { return state.data == address.stateId })?.display : this.state.billingStates?.find(state => { return state.data == address.stateId })?.display?.replace(' ', '_')) ?? billingCountryName[address?.countryCode?.toLowerCase()],
                            PostalCode: address?.zip?.substring(0, 5),
                            ThreeLetterIsoCode: threeLetterCode
                        },
                        ShippingAddress: {
                            StreetAddress: shippingAddress?.address1,
                            Locality: shippingAddress?.city,
                            Region: (shipToCountryCode?.toLowerCase() == "de" || shipToCountryCode?.toLowerCase() == "fr") ? threeLetterCode : (this.state.shipToStates?.find(state => { return state.data == shippingAddress.stateId })?.display?.split(' ')[0]?.length > 1 ? this.state.shipToStates?.find(state => { return state.data == shippingAddress.stateId })?.display : this.state.shipToStates?.find(state => { return state.data == shippingAddress.stateId })?.display?.replace(' ', '_')) ?? threeLetterCode,
                            PostalCode: shippingAddress?.zip?.substring(0, 5),
                            ThreeLetterIsoCode: threeLetterCode
                        }
                    },
                    CardInfo: {
                        FullNumber: this.state.ccNumber.replace('-', ''),
                        ExpirationMonth: parseInt(month),
                        ExpirationYear: parseInt('20' + year),
                        CreditCardVerificationNumber: this.state.securityCode,
                        CardType: ccTypeId
                    },
                    CurrencyCode: currency,
                    RelatedTo: 2,
                    CultureName: culture,
                    RelatedToId: claimNumber
                })
            }).then(function (res) {
                console.log(res);
                statusCode = res.status;
                return res.json();
            }).then(data => {
                //this.saveForLaterAction(this.props.history.location.state.claim, false);
                if (statusCode === 204) {
                    let errors = this.state.errors;
                    isAuthenticationSuccessful = false;
                    errors["general"] = true;
                    this.setState({ searching: false, errors: errors }, () => {
                        // Show error messages
                        console.log(statusCode);
                        return;
                    });

                }
                else if (statusCode === 200) {
                    if (data.isSuccessful) {
                        authenticationSessionId = data.sessionId;
                        authenticationTransactionId = data.transactionId;
                        if (data.isMethodSupported && data.isAuthenticationRequired) {
                            this.setState({ errors: errors, searching: true, ThreeDSMethodRedirectHtml: data.redirectHtml, showThreeDSMethodRedirect: true });
                        }
                        //Authentication payer
                        fetch('api/payment/authenticatePayer/'.concat(this.state.clientId), {
                            method: 'post',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                SessionId: authenticationSessionId,
                                TransactionId: authenticationTransactionId,
                                RelatedToId: claimNumber,
                                CurrencyCode: currency,
                                RelatedTo: 2,
                                CultureName: culture,
                                Device: {
                                    Browser: navigator.userAgent,
                                    BrowserDetails: {
                                        _3DSecureChallengeWindowSize: "FULL_SCREEN",
                                        acceptHeaders: "application/json",
                                        JavaEnabled: navigator.javaEnabled(),
                                        Language: navigator.language,
                                        ScreenHeight: window.screen.height.toString(),
                                        ScreenWidth: window.screen.width.toString(),
                                        ColorDepth: window.screen.colorDepth.toString(),
                                        TimeZone: new Date().getTimezoneOffset().toString()

                                    }
                                }
                            })
                        }).then(function (res) {
                            console.log(res);
                            statusCode = res.status;
                            return res.json();
                        }).then(data => {
                            //this.saveForLaterAction(this.props.history.location.state.claim, false);
                            if (statusCode === 204) {
                                let errors = this.state.errors;
                                isAuthenticationSuccessful = false;
                                errors["general"] = true;
                                this.setState({ searching: false, errors: errors }, () => {
                                    // Show error messages
                                    console.log(statusCode);
                                    return;
                                });

                            }
                            else if (statusCode === 200) {
                                if (data.isSuccessful) {
                                    if (data.isChallengeRedirect && data.isAuthenticationRequired) { // && data.AuthenticationStatus == "PENDING"
                                        isAuthenticationSuccessful = false;
                                        this.setState({ errors: errors, searching: false, ThreeDSChallengeRedirectHtml: data.redirectHtml, openThreeDSChallenge: true });
                                        eval(document.getElementById('authenticate-payer-script').text);
                                    }

                                    //Get Authentication Status API
                                    fetch('api/payment/getAuthenticationStatus/'.concat(this.state.clientId), {
                                        method: 'post',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({
                                            SessionId: authenticationSessionId,
                                            TransactionId: authenticationTransactionId,
                                            RelatedToId: claimNumber,
                                            CultureName: culture,
                                            IsChallengeResponse: !isAuthenticationSuccessful
                                        })
                                    }).then(function (res) {
                                        console.log(res);
                                        statusCode = res.status;
                                        return res.json();
                                    }).then(data => {
                                        this.setState({ searching: true, openThreeDSChallenge: false });
                                        isAuthenticationSuccessful = true;

                                        let amount = 0;
                                        let failureReason = "";
                                        let eligible = false;
                                        let fraud = false;
                                        let fmip = false;
                                        let isPreAuthCharge = false;

                                        if (this.state.isApproved) {
                                            amount = this.props.entitlementDetails.deductible;
                                            // call payment service
                                            fetch('api/payment/'.concat(this.state.clientId), {
                                                method: 'post',
                                                headers: {
                                                    'Content-Type': 'application/json'
                                                },
                                                body: JSON.stringify({
                                                    Amount: parseFloat(amount),
                                                    CardHolder: {
                                                        FirstName: this.state.firstName,
                                                        LastName: this.state.lastName,
                                                        SelectedAddress: this.state.selectedAddress,
                                                        BillingAddress: {
                                                            StreetAddress: address?.address1,
                                                            Locality: address?.city,
                                                            Region: (address?.countryCode?.toLowerCase() == "de" || address?.countryCode?.toLowerCase() == "fr") ? billingCountryName[address?.countryCode?.toLowerCase()] : (this.state.billingStates?.find(state => { return state.data == address.stateId })?.display.split(' ')[0]?.length > 1 ? this.state.billingStates?.find(state => { return state.data == address.stateId })?.display : this.state.billingStates?.find(state => { return state.data == address.stateId })?.display?.replace(' ', '_')) ?? billingCountryName[address?.countryCode?.toLowerCase()],
                                                            PostalCode: address?.zip?.substring(0, 5),
                                                            ThreeLetterIsoCode: threeLetterCode
                                                        },
                                                        ShippingAddress: {
                                                            StreetAddress: shippingAddress?.address1,
                                                            Locality: shippingAddress?.city,
                                                            Region: (shipToCountryCode?.toLowerCase() == "de" || shipToCountryCode?.toLowerCase() == "fr") ? threeLetterCode : (this.state.shipToStates?.find(state => { return state.data == shippingAddress.stateId })?.display?.split(' ')[0]?.length > 1 ? this.state.shipToStates?.find(state => { return state.data == shippingAddress.stateId })?.display : this.state.shipToStates?.find(state => { return state.data == shippingAddress.stateId })?.display?.replace(' ', '_')) ?? threeLetterCode,
                                                            PostalCode: shippingAddress?.zip?.substring(0, 5),
                                                            ThreeLetterIsoCode: threeLetterCode
                                                        }
                                                    },
                                                    CardInfo: {
                                                        FullNumber: this.state.ccNumber.replace('-', ''),
                                                        ExpirationMonth: parseInt(month),
                                                        ExpirationYear: parseInt('20' + year),
                                                        CreditCardVerificationNumber: this.state.securityCode,
                                                        CardType: ccTypeId
                                                    },
                                                    CurrencyCode: currency,
                                                    RelatedTo: 2,
                                                    CultureName: culture,
                                                    RelatedToIds: [claimNumber],
                                                    IsPreAuthCharge: isPreAuthCharge,
                                                    FullDeductible: parseFloat(this.props.entitlementDetails.deductible),
                                                    AuthenticationSessionId: authenticationSessionId,
                                                    AuthenticationTransactionId: authenticationTransactionId,
                                                    Device: {
                                                        Browser: navigator.userAgent,
                                                        BrowserDetails: {
                                                            _3DSecureChallengeWindowSize: "FULL_SCREEN",
                                                            acceptHeaders: "application/json",
                                                            JavaEnabled: navigator.javaEnabled(),
                                                            Language: navigator.language,
                                                            ScreenHeight: window.screen.height.toString(),
                                                            ScreenWidth: window.screen.width.toString(),
                                                            ColorDepth: window.screen.colorDepth.toString(),
                                                            TimeZone: new Date().getTimezoneOffset().toString()

                                                        }
                                                    }
                                                })
                                            }).then(function (res) {
                                                console.log(res);
                                                statusCode = res.status;
                                                return res.json();
                                            }).then(data => {
                                                //this.saveForLaterAction(this.props.history.location.state.claim, false);
                                                if (statusCode === 204) {
                                                    let errors = this.state.errors;
                                                    errors["general"] = true;
                                                    this.setState({ searching: false, errors: errors }, () => {
                                                        // Show error messages
                                                        console.log(statusCode);
                                                        return;
                                                    });
                                                }
                                                else if (statusCode === 200) {
                                                    if (!data.isSuccessful) {
                                                        let errorsResponse = data.errors;
                                                        if (errorsResponse.length > 0) {
                                                            let authenticationErrorCode = null;
                                                            errorsResponse.map(function (error) {
                                                                if (error.errorCode === '9576') {
                                                                    errors["ccInvalid"] = true;
                                                                }
                                                                else if (error.errorCode.includes('EXPIRED')) {
                                                                    errors["ccExpired"] = true;
                                                                }
                                                                else if (error.errorCode.includes('FUND')) {
                                                                    errors["funds"] = true;
                                                                }
                                                                else if (error.errorCode.includes('ADDRESS')) {
                                                                    errors["billingAddressMatch"] = true;
                                                                }
                                                                else if (error.errorCode.includes('DECLINED')) {
                                                                    errors["declined"] = true;
                                                                }
                                                                else if ((error.errorCode.includes('SN-CHASE-AUTHENTICATION-FAILED')) || (error.errorCode.includes('SN-CHASE-AUTHENTICATION-PENDING'))) {
                                                                    authenticationErrorCode = error.errorCode;
                                                                    errors["authenticationError"] = true;
                                                                }
                                                                else {
                                                                    errors["general"] = true;
                                                                }
                                                            });

                                                            if (typeof authenticationErrorCode != "undefined" && authenticationErrorCode != null) {
                                                                this.getErrorStatus(authenticationErrorCode);
                                                                this.sendAuthenticationErrorEmail();
                                                            }

                                                            this.setState({ errors: errors, searching: false });

                                                            return;
                                                        }
                                                    }
                                                    valid = true;

                                                    if (amount > 1) {
                                                        let historyNotes = `Deductible collected in amount of ${amount}`;
                                                        createClaimHistoryItem(this.props.entitlementDetails.claimId, this.state.clientId, `Deductible Collected`, historyNotes);
                                                    }
                                                    createNewAuthorization(this.props.entitlementDetails.claimId, billingCodeDisplayIds.unitReplacementFullBuyOut,
                                                        authorizationStatus.approved, this.state.clientId)
                                                        .then(() => {
                                                            // update repair
                                                            updateRepair(this.state.clientId, this.state.claimNumber, "CRCP") // SEU-1548, change to CRCP
                                                                .then(res => {
                                                                    console.log(res);

                                                                    // create repair at apple
                                                                    createRepair(this.state.clientId, this.state.claimNumber)
                                                                        .then(res => {
                                                                            if (res.ok) {
                                                                                //update partner case
                                                                                updatePartnerCase(this.state.claimNumber, "CRCN", this.state.clientId);

                                                                                let countryCode = this.props.entitlementDetails?.countryCode ?? "us";
                                                                                let languageCode = this.context.languageContext.language ?? "en";
                                                                                let nonUSMiniSiteURL = window.location.origin + '/vendor/apple/' + this.props.entitlementDetails.caseNumber + '/' + this.props.entitlementDetails.email;
                                                                                let miniSiteURL = countryCode.toLowerCase() == "us" ? '<a href="https://www.aigtheftandloss.com">www.aigtheftandloss.com</a>' : nonUSMiniSiteURL;
                                                                                let emailType = 7;
                                                                                if (countryCode.toLowerCase() == "aus") {
                                                                                    emailType = 76;
                                                                                }
                                                                                else if (countryCode.toLowerCase() == "uk") {
                                                                                    emailType = 77;
                                                                                }
                                                                                else if (countryCode.toLowerCase() == "de" && languageCode.toLowerCase() == "de") {
                                                                                    emailType = 78;
                                                                                }
                                                                                else if (countryCode.toLowerCase() == "de" && languageCode.toLowerCase() == "en") {
                                                                                    emailType = 79;
                                                                                }
                                                                                else if (countryCode.toLowerCase() == "fr" && languageCode.toLowerCase() == "fr") {
                                                                                    emailType = 124;
                                                                                }
                                                                                else if (countryCode.toLowerCase() == "fr" && languageCode.toLowerCase() == "en") {
                                                                                    emailType = 166;
                                                                                }
                                                                                else if (countryCode.toLowerCase() == "it" && languageCode.toLowerCase() == "it") {
                                                                                    emailType = 125;
                                                                                }
                                                                                else if (countryCode.toLowerCase() == "it" && languageCode.toLowerCase() == "en") {
                                                                                    emailType = 167;
                                                                                }
                                                                                else if (countryCode.toLowerCase() == "es" && languageCode.toLowerCase() == "es") {
                                                                                    emailType = 126;
                                                                                }
                                                                                else if (countryCode.toLowerCase() == "es" && languageCode.toLowerCase() == "en") {
                                                                                    emailType = 168;
                                                                                }
                                                                                else {
                                                                                    emailType = 7;
                                                                                }
                                                                                // Send Approved Mail
                                                                                fetch('api/communication/'.concat(this.state.clientId), {
                                                                                    method: 'post',
                                                                                    headers: {
                                                                                        'Content-Type': 'application/json'
                                                                                    },
                                                                                    body: JSON.stringify({

                                                                                        emailModel: {

                                                                                            Recipients: [this.props.entitlementDetails.email],
                                                                                            Cc: [],
                                                                                            Bcc: [],
                                                                                            IsBodyHtml: true,
                                                                                            emailType: emailType
                                                                                        },
                                                                                        serviceModel: {
                                                                                            SubscriberName: this.state.firstName + ' ' + this.state.lastName,
                                                                                            firstName: this.state.firstName,
                                                                                            lastName: this.state.lastName,
                                                                                            MiniSiteURL: miniSiteURL,
                                                                                            WirelessNumber: this.props.entitlementDetails.contactPhone,
                                                                                            DeviceMakeandModel: makeAndModelNumber,
                                                                                            SerialNumber: this.props.entitlementDetails.contractAsset?.serialNumber,
                                                                                            CaseNumber: this.props.entitlementDetails.caseNumber,
                                                                                            CaseNumberDate: moment(this.props.entitlementDetails.incidentDate).format("MM/DD/YYYY"),
                                                                                            ClaimId: parseInt(this.props.entitlementDetails.claimId),
                                                                                            WarrantyObjectTypeId: 2
                                                                                        }
                                                                                    })
                                                                                }).then(function (res) {
                                                                                    console.log(res);
                                                                                    statusCode = res.status;
                                                                                    return res.json();
                                                                                })

                                                                                // navigate to successful confirmation
                                                                                this.props.history.push('/vendor/apple/confirmation', {
                                                                                    statusType: "approvedClaim",
                                                                                    caseNumber: this.props.entitlementDetails.caseNumber,
                                                                                    client: this.state.clientId,
                                                                                    contractNumber: this.props.entitlementDetails.contract.contractNumber,
                                                                                    claimNumber: this.state.claimNumber,
                                                                                    claimId: this.props.entitlementDetails.claimId
                                                                                }); // Successful payment
                                                                            }
                                                                            else {
                                                                                // create repair failed
                                                                                console.log('create repair failed - move to review');
                                                                                this.props.history.push('/vendor/apple/confirmation', {
                                                                                    statusType: "claimInReview",
                                                                                    caseNumber: this.props.entitlementDetails.caseNumber,
                                                                                    client: this.state.clientId,
                                                                                    contractNumber: this.props.entitlementDetails.contract.contractNumber,
                                                                                    claimNumber: this.state.claimNumber,
                                                                                    claimId: this.props.entitlementDetails.claimId
                                                                                }); // Failed rule
                                                                            }

                                                                            this.setState({ searching: false, paid: true });
                                                                        });
                                                                });
                                                        });

                                                }
                                            });
                                            this.removeQueueItem();
                                            if (this.state.authenticationErrorFlag) {
                                                createOrUpdateClaimConfiguration(this.props.entitlementDetails?.claimId ?? 0, 36, "0", this.state.clientId);
                                            }
                                        }
                                        else {
                                            let token = "";
                                            let caseId = "";
                                            let transUnionResult = 0;
                                            let transUnionDecision = null;
                                            var storedSession = typeof window.TrustevV2 == "undefined" ? "" : window.TrustevV2.SessionId;
                                            sessionId = (storedSession == "" || typeof storedSession == "undefined") ? "" : storedSession;

                                            let transUnionCaseModel = {
                                                PartyId: this.props.entitlementDetails.party.partyId,
                                                Addresses: [
                                                    {
                                                        Address1: address.address1,
                                                        Address2: address.address2,
                                                        City: address.city,
                                                        CountryCode: countryCode?.toUpperCase(),
                                                        FirstName: this.props.entitlementDetails.party.firstName,
                                                        LastName: this.props.entitlementDetails.party.lastName,
                                                        PostalCode: address.zip?.substring(0, 5),
                                                        State: this.state.states.find(state => { return state.data == address.stateId })?.abbreviation ?? null,
                                                        AddressType: 1
                                                    },
                                                    {
                                                        Address1: alternateAddress.address1,
                                                        Address2: alternateAddress.address2,
                                                        City: alternateAddress.city,
                                                        CountryCode: countryCode?.toUpperCase(),
                                                        FirstName: this.props.entitlementDetails.party.firstName,
                                                        LastName: this.props.entitlementDetails.party.lastName,
                                                        PostalCode: alternateAddress.zip?.substring(0, 5),
                                                        State: this.state.states.find(state => { return state.data == alternateAddress.stateId })?.abbreviation ?? null,
                                                        AddressType: 2
                                                    }
                                                ],
                                                Phone: this.props.entitlementDetails.party.partyPhoneModel[0].number,
                                                EmailAddress: this.props.entitlementDetails.email,
                                                FirstName: this.props.entitlementDetails.party.firstName,
                                                LastName: this.props.entitlementDetails.party.lastName,
                                                ClaimNumber: this.state.claimNumber,
                                                ClaimId: parseInt(this.props.entitlementDetails.claimId),
                                                Token: "", // TBD
                                                SessionId: sessionId,
                                                ClientId: this.state.clientId
                                            };

                                            let iDologyRequestModel = {
                                                firstName: this.props.entitlementDetails.party.firstName,
                                                lastName: this.props.entitlementDetails.party.lastName,
                                                phone: this.props.entitlementDetails.party.partyPhoneModel[0].number,
                                                emailAddress: this.props.entitlementDetails.email,
                                                mailingAddress:
                                                {
                                                    address1: address.address1,
                                                    address2: address.address2,
                                                    city: address.city,
                                                    zip: address.zip,
                                                    state: this.state.states.find(state => { return state.data == address.stateId })?.abbreviation ?? null,
                                                    stateName: this.state.states.find(state => { return state.data == address.stateId })?.display ?? null
                                                },
                                                alternateAddress: {
                                                    address1: alternateAddress.address1,
                                                    address2: alternateAddress.address2,
                                                    city: alternateAddress.city,
                                                    zip: alternateAddress.zip,
                                                    state: this.state.states.find(state => { return state.data == alternateAddress.stateId })?.abbreviation ?? null,
                                                    stateName: this.state.states.find(state => { return state.data == alternateAddress.stateId })?.display ?? null
                                                },
                                                claimNumber: this.state.claimNumber,
                                                claimId: parseInt(this.props.entitlementDetails.claimId),
                                                countryCode: threeLetterCode
                                            };

                                            fetch('api/rulesEngine/ExecuteRulesAndTransUnion/'.concat(this.state.clientId), {
                                                method: 'post',
                                                headers: {
                                                    'Content-Type': 'application/json'
                                                },
                                                body: JSON.stringify({
                                                    addCaseModel: transUnionCaseModel,
                                                    iDologyRequest: iDologyRequestModel,
                                                    claimId: this.props.entitlementDetails.claimId?.toString(),
                                                    countryCode: countryCode,
                                                    countryName: countryName,
                                                    customerVerificationResult: this.state.customerVerificationResult?.toString(),
                                                    rulesExecutionModel: {
                                                        rulesEngineExecutionModel: {
                                                            clientId: this.state.clientId,
                                                            contractId: parseInt(this.props.entitlementDetails.contract.contractId),
                                                            claimId: parseInt(this.props.entitlementDetails.claimId)
                                                        },
                                                        rules: [1, 2, 3]
                                                    }
                                                })
                                            }).then(res => {
                                                if (res.ok) {
                                                    res.json().then(rulesExecutionResponse => {
                                                        console.log(rulesExecutionResponse);
                                                        let rulesResult = rulesExecutionResponse.rulesExecutionResult;
                                                        transUnionDecision = rulesExecutionResponse.transUnionDecision;
                                                        eligible = rulesResult[0]?.success;
                                                        fraud = rulesResult[1]?.success;
                                                        fmip = (typeof rulesResult[2] == "undefined" || rulesResult[2] == null) ? true : rulesResult[2].success;

                                                        token = transUnionDecision?.tokenId;
                                                        sessionId = transUnionDecision?.sessionId;
                                                        caseId = transUnionDecision?.caseId;
                                                        this.setState({ customerVerificationResult: transUnionDecision?.result ?? "0" });
                                                        transUnionResult = transUnionDecision?.result ?? 0;


                                                        if (eligible && fraud && fmip && transUnionResult == 1) {
                                                            //if (eligible && fraud && fmip) {
                                                            console.log("Passed");
                                                            amount = this.props.entitlementDetails.deductible;
                                                        }
                                                        else {
                                                            console.log("Didn't pass");
                                                            amount = 0.01;
                                                            isPreAuthCharge = this.state.clientId === 'apple' && countryCode.toLowerCase() != "us" ? true : false;

                                                        }
                                                        // call payment service
                                                        fetch('api/payment/'.concat(this.state.clientId), {
                                                            method: 'post',
                                                            headers: {
                                                                'Content-Type': 'application/json'
                                                            },
                                                            body: JSON.stringify({
                                                                Amount: parseFloat(amount),
                                                                CardHolder: {
                                                                    FirstName: this.state.firstName,
                                                                    LastName: this.state.lastName,
                                                                    SelectedAddress: this.state.selectedAddress,
                                                                    BillingAddress: {
                                                                        StreetAddress: address.address1,
                                                                        Locality: address.city,
                                                                        Region: (address?.countryCode.toLowerCase() == "de" || address?.countryCode.toLowerCase() == "fr") ? billingCountryName[address?.countryCode?.toLowerCase()] : (this.state.billingStates?.find(state => { return state.data == address.stateId })?.display.split(' ')[0].length > 1 ? this.state.billingStates?.find(state => { return state.data == address.stateId })?.display : this.state.billingStates?.find(state => { return state.data == address.stateId })?.display.replace(' ', '_')) ?? billingCountryName[address?.countryCode?.toLowerCase()],
                                                                        PostalCode: address.zip?.substring(0, 5),
                                                                        ThreeLetterIsoCode: threeLetterCode
                                                                    },
                                                                    ShippingAddress: {
                                                                        StreetAddress: shippingAddress.address1,
                                                                        Locality: shippingAddress.city,
                                                                        Region: (shipToCountryCode.toLowerCase() == "de" || shipToCountryCode.toLowerCase() == "fr") ? threeLetterCode : (this.state.shipToStates.find(state => { return state.data == shippingAddress.stateId })?.display.split(' ')[0].length > 1 ? this.state.shipToStates.find(state => { return state.data == shippingAddress.stateId })?.display : this.state.shipToStates.find(state => { return state.data == shippingAddress.stateId })?.display.replace(' ', '_')) ?? threeLetterCode,
                                                                        PostalCode: shippingAddress.zip?.substring(0, 5),
                                                                        ThreeLetterIsoCode: threeLetterCode
                                                                    }
                                                                },
                                                                CardInfo: {
                                                                    FullNumber: this.state.ccNumber.replace('-', ''),
                                                                    ExpirationMonth: parseInt(month),
                                                                    ExpirationYear: parseInt('20' + year),
                                                                    CreditCardVerificationNumber: this.state.securityCode,
                                                                    CardType: ccTypeId
                                                                },
                                                                CurrencyCode: currency,
                                                                RelatedTo: 2,
                                                                CultureName: culture,
                                                                RelatedToIds: [claimNumber],
                                                                IsPreAuthCharge: isPreAuthCharge,
                                                                FullDeductible: parseFloat(this.props.entitlementDetails.deductible),
                                                                AuthenticationSessionId: authenticationSessionId,
                                                                AuthenticationTransactionId: authenticationTransactionId,
                                                                Device: {
                                                                    Browser: navigator.userAgent,
                                                                    BrowserDetails: {
                                                                        _3DSecureChallengeWindowSize: "FULL_SCREEN",
                                                                        acceptHeaders: "application/json",
                                                                        JavaEnabled: navigator.javaEnabled(),
                                                                        Language: navigator.language,
                                                                        ScreenHeight: window.screen.height.toString(),
                                                                        ScreenWidth: window.screen.width.toString(),
                                                                        ColorDepth: window.screen.colorDepth.toString(),
                                                                        TimeZone: new Date().getTimezoneOffset().toString()

                                                                    }
                                                                }
                                                            })
                                                        }).then(function (res) {
                                                            console.log(res);
                                                            statusCode = res.status;
                                                            return res.json();
                                                        }).then(data => {
                                                            //this.saveForLaterAction(this.props.history.location.state.claim, false);
                                                            if (statusCode === 204) {
                                                                let errors = this.state.errors;
                                                                errors["general"] = true;
                                                                this.setState({ searching: false, errors: errors }, () => {
                                                                    // Show error messages
                                                                    console.log(statusCode);
                                                                    return;
                                                                });
                                                                // TransUnion Call
                                                                addTUStatus(this.state.claimNumber, token, caseId, 2, this.state.clientId);
                                                            }
                                                            else if (statusCode === 200) {
                                                                if (!data.isSuccessful) {
                                                                    let errorsResponse = data.errors;
                                                                    if (errorsResponse.length > 0) {
                                                                        let authenticationErrorCode = null;
                                                                        errorsResponse.map(function (error) {
                                                                            if (error.errorCode === '9576') {
                                                                                errors["ccInvalid"] = true;
                                                                            }
                                                                            else if (error.errorCode.includes('EXPIRED')) {
                                                                                errors["ccExpired"] = true;
                                                                            }
                                                                            else if (error.errorCode.includes('FUND')) {
                                                                                errors["funds"] = true;
                                                                            }
                                                                            else if (error.errorCode.includes('ADDRESS')) {
                                                                                errors["billingAddressMatch"] = true;
                                                                            }
                                                                            else if (error.errorCode.includes('DECLINED')) {
                                                                                errors["declined"] = true;
                                                                            }
                                                                            else if ((error.errorCode.includes('SN-CHASE-AUTHENTICATION-FAILED')) || (error.errorCode.includes('SN-CHASE-AUTHENTICATION-PENDING'))) {
                                                                                authenticationErrorCode = error.errorCode;
                                                                                errors["authenticationError"] = true;
                                                                            }
                                                                            else {
                                                                                errors["general"] = true;
                                                                            }
                                                                        });

                                                                        if (typeof authenticationErrorCode != "undefined" && authenticationErrorCode != null) {
                                                                            this.getErrorStatus(authenticationErrorCode);
                                                                            this.sendAuthenticationErrorEmail();
                                                                        }

                                                                        this.setState({ errors: errors, searching: false });

                                                                        return;
                                                                    }
                                                                }
                                                                valid = true;
                                                                //update partner case status to CSDP after payment is successful
                                                                updatePartnerCase(this.state.claimNumber, "CSDP", this.state.clientId);
                                                                // update authentication error flag to "0" - false
                                                                if (this.state.authenticationErrorFlag) {
                                                                    createOrUpdateClaimConfiguration(this.props.entitlementDetails?.claimId ?? 0, 36, "0", this.state.clientId);
                                                                }
                                                                if (amount > 1) {
                                                                    let historyNotes = `Deductible collected in amount of ${amount}`;
                                                                    createClaimHistoryItem(this.props.entitlementDetails.claimId, this.state.clientId, `Deductible Collected`, historyNotes);
                                                                }
                                                                // TransUnion Call
                                                                if (transUnionResult === 1) {
                                                                    // Add Completed Status
                                                                    addTUStatus(this.state.claimNumber, token, caseId, 0, this.state.clientId);
                                                                }

                                                                // TransUnion Call
                                                                if (!fraud || transUnionResult != 1)
                                                                    //if (!fraud)
                                                                    failureReason = 'Investigation Review'
                                                                console.log('1:', failureReason)
                                                                if (!eligible)
                                                                    failureReason = 'Eligibility Review'
                                                                console.log('2:', failureReason)

                                                                if (!fmip)
                                                                    failureReason = 'Eligibility Review'
                                                                console.log('3:', failureReason)



                                                                //Add to Agent Queue
                                                                fetch('api/queue/queueitem/'.concat(this.state.clientId), {
                                                                    method: 'post',
                                                                    headers: {
                                                                        'Content-Type': 'application/json'
                                                                    },
                                                                    body: JSON.stringify({
                                                                        ClientId: this.state.clientId,
                                                                        ClaimNumber: this.props.entitlementDetails.claimNumber,
                                                                        ClaimId: parseInt(this.props.entitlementDetails.claimId),
                                                                        ContractId: parseInt(this.props.entitlementDetails.contract.contractId),
                                                                        IsEligible: eligible,
                                                                        IsFraud: fraud,
                                                                        IsFMiP: fmip,
                                                                        TransUnionResult: transUnionResult,
                                                                        FailureReason: failureReason
                                                                    })
                                                                }).then(res => {
                                                                    if (res.ok) {

                                                                        // SRV - moving this lower while we wait for create repair
                                                                        //this.setState({ searching: false, paid: true });
                                                                        // Moving the eligibility rule check after fraud rule check.If Fraud rules fail user will be
                                                                        //redircted to upload docs screen.
                                                                        if (!fraud || transUnionResult != 1) {

                                                                            let countryCode = this.props.entitlementDetails?.countryCode ?? "us";
                                                                            let languageCode = this.context.languageContext.language ?? "en";
                                                                            let documentRequired = "Government ID or Proof of Purchase";
                                                                            let emailType = 9;
                                                                            if (countryCode.toLowerCase() == "aus") {
                                                                                emailType = 33;
                                                                            }
                                                                            else if (countryCode.toLowerCase() == "uk") {
                                                                                emailType = 34;
                                                                            }
                                                                            else if (countryCode.toLowerCase() == "de" && languageCode.toLowerCase() == "en") {
                                                                                emailType = 35;
                                                                            }
                                                                            else if (countryCode.toLowerCase() == "de" && languageCode.toLowerCase() == "de") {
                                                                                emailType = 36;
                                                                                documentRequired = "Personalausweis oder Kaufnachweis";
                                                                            }
                                                                            else if (countryCode.toLowerCase() == "fr" && languageCode.toLowerCase() == "fr") {
                                                                                emailType = 133;
                                                                                documentRequired = "Pièce d'identité gouvernementale ou preuve d'achat";
                                                                            }
                                                                            else if (countryCode.toLowerCase() == "it" && languageCode.toLowerCase() == "it") {
                                                                                emailType = 134;
                                                                                documentRequired = "ID governativo o prova di acquisto";
                                                                            }
                                                                            else if (countryCode.toLowerCase() == "es" && languageCode.toLowerCase() == "es") {
                                                                                emailType = 135;
                                                                                documentRequired = "Identificación del gobierno o prueba de compra";
                                                                            }
                                                                            else if (countryCode.toLowerCase() == "fr" && languageCode.toLowerCase() == "en") {
                                                                                emailType = 175;
                                                                            }
                                                                            else if (countryCode.toLowerCase() == "it" && languageCode.toLowerCase() == "en") {
                                                                                emailType = 176;
                                                                            }
                                                                            else if (countryCode.toLowerCase() == "es" && languageCode.toLowerCase() == "en") {
                                                                                emailType = 177;
                                                                            }
                                                                            else {
                                                                                emailType = 9;
                                                                            }
                                                                            this.props.history.push("/vendor/apple/uploaddocs/", { isFraud: true, entitlementDetails: this.props.entitlementDetails, statusType: "docsPending", clientId: this.state.clientId, claimNumber: this.state.claimNumber, caseNumber: this.props.entitlementDetails.caseNumber, claimId: this.props.entitlementDetails.claimId, ccNumber: this.state.ccNumber.substring(12, 16), ccType: this.state.ccType })


                                                                            //this.props.history.push('/vendor/apple/confirmation', {
                                                                            //    statusType: "docsPending",
                                                                            //    caseNumber: this.props.entitlementDetails.caseNumber,
                                                                            //    client: this.state.clientId,
                                                                            //    contractNumber: this.props.entitlementDetails.contract.contractNumber,
                                                                            //    claimNumber: this.state.claimNumber,
                                                                            //    claimId: this.props.entitlementDetails.claimId
                                                                            //}); // Fraud rule                                                                               
                                                                            var miniSiteURL = window.location.origin + '/vendor/apple/' + this.props.entitlementDetails.caseNumber + '/' + this.props.entitlementDetails.email;
                                                                            fetch('api/communication/'.concat(this.state.clientId), {
                                                                                method: 'post',
                                                                                headers: {
                                                                                    'Content-Type': 'application/json'
                                                                                },
                                                                                body: JSON.stringify({

                                                                                    emailModel: {

                                                                                        Recipients: [this.props.entitlementDetails.email],
                                                                                        Cc: [],
                                                                                        Bcc: [],
                                                                                        IsBodyHtml: true,
                                                                                        emailType: emailType
                                                                                    },
                                                                                    serviceModel: {
                                                                                        FirstName: this.state.firstName,
                                                                                        LastName: this.state.lastName,
                                                                                        MiniSiteURL: miniSiteURL,
                                                                                        WirelessNumber: countryCode.toLowerCase() == "us" ? this.props.entitlementDetails.contactPhone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3") : this.props.entitlementDetails.contactPhone,
                                                                                        DeviceMakeandModel: makeAndModelNumber,
                                                                                        CaseNumber: this.props.entitlementDetails.caseNumber,
                                                                                        CaseNumberDate: moment(this.props.entitlementDetails.incidentDate).format("MM/DD/YYYY"),
                                                                                        DocumentDeliveryMailAddress: "Claims Department, 650 Missouri Ave. Jeffersonville, IN 47130",
                                                                                        DocumentDeliveryEmailAddress: "AIGClaimsCenter@sndirect.com",
                                                                                        CallCenterTollFreeNo: this.state.callNumber,
                                                                                        ClaimNumber: this.props.entitlementDetails.caseNumber,
                                                                                        ClaimSetupDate: moment(this.props.entitlementDetails.claimSetupDate).format("MM/DD/YYYY"),
                                                                                        SerialNumber: this.props.entitlementDetails.contractAsset.serialNumber,
                                                                                        DocumentRequired: documentRequired, //this.props.DocumentRequired
                                                                                        ClaimId: parseInt(this.props.entitlementDetails.claimId),
                                                                                        WarrantyObjectTypeId: 2
                                                                                    }
                                                                                })
                                                                            }).then(function (res) {
                                                                                console.log(res);
                                                                                statusCode = res.status;
                                                                                return res.json();
                                                                            })

                                                                        }
                                                                        else if (!eligible) {
                                                                            this.props.history.push('/vendor/apple/confirmation', {
                                                                                statusType: "claimInReview",
                                                                                caseNumber: this.props.entitlementDetails.caseNumber,
                                                                                client: this.state.clientId,
                                                                                contractNumber: this.props.entitlementDetails.contract.contractNumber,
                                                                                claimNumber: this.state.claimNumber,
                                                                                claimId: this.props.entitlementDetails.claimId
                                                                            }); // Failed rule
                                                                        }
                                                                        else if (!fmip) {
                                                                            this.props.history.push('/vendor/apple/confirmation', {
                                                                                statusType: "claimInReview",
                                                                                caseNumber: this.props.entitlementDetails.caseNumber,
                                                                                client: this.state.clientId,
                                                                                contractNumber: this.props.entitlementDetails.contract.contractNumber,
                                                                                claimNumber: this.state.claimNumber,
                                                                                claimId: this.props.entitlementDetails.claimId
                                                                            }); // Failed rule
                                                                        }
                                                                        else {
                                                                            createNewAuthorization(this.props.entitlementDetails.claimId, billingCodeDisplayIds.unitReplacementFullBuyOut,
                                                                                authorizationStatus.approved, this.state.clientId)
                                                                                .then(() => {
                                                                                    // update repair
                                                                                    updateRepair(this.state.clientId, this.state.claimNumber, "CRCP") // SEU-1548, change to CRCP
                                                                                        .then(res => {
                                                                                            console.log(res);

                                                                                            // create repair at apple
                                                                                            createRepair(this.state.clientId, this.state.claimNumber)
                                                                                                .then(res => {
                                                                                                    if (res.ok) {
                                                                                                        //update partner case
                                                                                                        updatePartnerCase(this.state.claimNumber, "CRCN", this.state.clientId);

                                                                                                        let countryCode = this.props.entitlementDetails?.countryCode ?? "us";
                                                                                                        let languageCode = this.context.languageContext.language?? "en";
                                                                                                        let nonUSMiniSiteURL = window.location.origin + '/vendor/apple/' + this.props.entitlementDetails.caseNumber + '/' + this.props.entitlementDetails.email;
                                                                                                        let miniSiteURL = countryCode.toLowerCase() == "us" ? '<a href="https://www.aigtheftandloss.com">www.aigtheftandloss.com</a>' : nonUSMiniSiteURL;
                                                                                                        let emailType = 7;
                                                                                                        if (countryCode.toLowerCase() == "aus") {
                                                                                                            emailType = 76;
                                                                                                        }
                                                                                                        else if (countryCode.toLowerCase() == "uk") {
                                                                                                            emailType = 77;
                                                                                                        }
                                                                                                        else if (countryCode.toLowerCase() == "de" && languageCode.toLowerCase() == "de") {
                                                                                                            emailType = 78;
                                                                                                        }
                                                                                                        else if (countryCode.toLowerCase() == "de" && languageCode.toLowerCase() == "en") {
                                                                                                            emailType = 79;
                                                                                                        }
                                                                                                        else if (countryCode.toLowerCase() == "fr" && languageCode.toLowerCase() == "fr") {
                                                                                                            emailType = 124;
                                                                                                        }
                                                                                                        else if (countryCode.toLowerCase() == "fr" && languageCode.toLowerCase() == "en") {
                                                                                                            emailType = 166;
                                                                                                        }
                                                                                                        else if (countryCode.toLowerCase() == "it" && languageCode.toLowerCase() == "it") {
                                                                                                            emailType = 125;
                                                                                                        }
                                                                                                        else if (countryCode.toLowerCase() == "it" && languageCode.toLowerCase() == "en") {
                                                                                                            emailType = 167;
                                                                                                        }
                                                                                                        else if (countryCode.toLowerCase() == "es" && languageCode.toLowerCase() == "es") {
                                                                                                            emailType = 126;
                                                                                                        }
                                                                                                        else if (countryCode.toLowerCase() == "es" && languageCode.toLowerCase() == "en") {
                                                                                                            emailType = 168;
                                                                                                        }
                                                                                                        else {
                                                                                                            emailType = 7;
                                                                                                        }
                                                                                                        // Send Approved Mail
                                                                                                        fetch('api/communication/'.concat(this.state.clientId), {
                                                                                                            method: 'post',
                                                                                                            headers: {
                                                                                                                'Content-Type': 'application/json'
                                                                                                            },
                                                                                                            body: JSON.stringify({

                                                                                                                emailModel: {

                                                                                                                    Recipients: [this.props.entitlementDetails.email],
                                                                                                                    Cc: [],
                                                                                                                    Bcc: [],
                                                                                                                    IsBodyHtml: true,
                                                                                                                    emailType: emailType
                                                                                                                },
                                                                                                                serviceModel: {
                                                                                                                    SubscriberName: this.state.firstName + ' ' + this.state.lastName,
                                                                                                                    firstName: this.state.firstName,
                                                                                                                    lastName: this.state.lastName,
                                                                                                                    MiniSiteURL: miniSiteURL,
                                                                                                                    WirelessNumber: this.props.entitlementDetails.contactPhone,
                                                                                                                    DeviceMakeandModel: makeAndModelNumber,
                                                                                                                    SerialNumber: this.props.entitlementDetails.contractAsset?.serialNumber,
                                                                                                                    CaseNumber: this.props.entitlementDetails.caseNumber,
                                                                                                                    CaseNumberDate: moment(this.props.entitlementDetails.incidentDate).format("MM/DD/YYYY"),
                                                                                                                    ClaimId: parseInt(this.props.entitlementDetails.claimId),
                                                                                                                    WarrantyObjectTypeId: 2
                                                                                                                }
                                                                                                            })
                                                                                                        }).then(function (res) {
                                                                                                            console.log(res);
                                                                                                            statusCode = res.status;
                                                                                                            return res.json();
                                                                                                        })

                                                                                                        this.removeQueueItem();// Removing the queue once the repair is created

                                                                                                        // navigate to successful confirmation
                                                                                                        this.props.history.push('/vendor/apple/confirmation', {
                                                                                                            statusType: "approvedClaim",
                                                                                                            caseNumber: this.props.entitlementDetails.caseNumber,
                                                                                                            client: this.state.clientId,
                                                                                                            contractNumber: this.props.entitlementDetails.contract.contractNumber,
                                                                                                            claimNumber: this.state.claimNumber,
                                                                                                            claimId: this.props.entitlementDetails.claimId
                                                                                                        }); // Successful payment
                                                                                                    }
                                                                                                    else {
                                                                                                        // create repair failed
                                                                                                        console.log('create repair failed - move to review');
                                                                                                        this.props.history.push('/vendor/apple/confirmation', {
                                                                                                            statusType: "claimInReview",
                                                                                                            caseNumber: this.props.entitlementDetails.caseNumber,
                                                                                                            client: this.state.clientId,
                                                                                                            contractNumber: this.props.entitlementDetails.contract.contractNumber,
                                                                                                            claimNumber: this.state.claimNumber,
                                                                                                            claimId: this.props.entitlementDetails.claimId
                                                                                                        }); // Failed rule
                                                                                                    }

                                                                                                    this.setState({ searching: false, paid: true });
                                                                                                });
                                                                                        });
                                                                                });
                                                                        }
                                                                    }
                                                                    else {
                                                                        //console.error(statusCode);
                                                                        // Show error messages
                                                                        let errors = this.state.errors;
                                                                        errors["internal"] = true;
                                                                        this.setState({ searching: false, errors }, () => {
                                                                            // Show error messages
                                                                            console.log(statusCode);
                                                                            return;
                                                                        });
                                                                        this.setState({ searching: false }, () => {
                                                                            return;
                                                                        });
                                                                    }
                                                                });

                                                            }
                                                        })

                                                    });
                                                }
                                                //})
                                                else {
                                                    let errors = this.state.errors;
                                                    errors["internal"] = true;
                                                    this.setState({ searching: false, errors }, () => {
                                                        // Show error messages
                                                        console.log(statusCode);
                                                        return;
                                                    });
                                                    this.setState({ searching: false }, () => {
                                                        return;
                                                    });
                                                }

                                            }) // rules execution
                                        }

                                    }) // Get Authentication Status API

                                }// Authentication payer successful
                                else {
                                    isAuthenticationSuccessful = false;
                                    let errors = this.state.errors;
                                    errors["general"] = true;
                                    this.setState({ errors: errors, searching: false });
                                    return;
                                }
                            }
                        });   // Authentication Payer end
                    }
                    else // unsuccessful Initiate authentication
                    {
                        let errorsResponse = data.errorMessage;
                        let errors = this.state.errors;
                        isAuthenticationSuccessful = false;
                        if (errorsResponse.errorCode === '9576') {
                            errors["ccInvalid"] = true;
                        }
                        else if (errorsResponse.errorCode.includes('EXPIRED')) {
                            errors["ccExpired"] = true;
                        }
                        else if (errorsResponse.errorCode.includes('FUND')) {
                            errors["funds"] = true;
                        }
                        else if (errorsResponse.errorCode.includes('ADDRESS')) {
                            errors["billingAddressMatch"] = true;
                        }
                        else if (errorsResponse.errorCode.includes('DECLINED')) {
                            errors["declined"] = true;
                        }
                        else {
                            errors["general"] = true;
                        }
                        this.setState({ errors: errors, searching: false });

                        return;
                    }
                }
            }) // Authentication API
        } // Validation
        else {
            let errors = this.state.errors;
            let state = this.state;
            let fieldName = "";
            let validations = "";
            //console.log(this.validator.getErrorMessages());
            //console.log(this.state);
            for (let [key, value] of Object.entries(state)) {
                switch (key) {
                    case "ccNumber":
                        fieldName = "creditCardNumber";
                        validations = this.props.entitlementDetails?.countryCode?.toLowerCase() === "us" ? "required|card_num" : "required|card_num|card_notsupported";
                        break;
                    case "cardExpires":
                        fieldName = "expirationDate";
                        validations = "required|card_expires";
                        break;
                    case "securityCode":
                        fieldName = "securityCode"
                        validations = "required|cvv";
                        break;
                    case "billingAddress":
                        fieldName = "billingAddress";
                        validations = this.state.selectedAddress == "billingAddress" ? "required" : "";
                        break;
                    default:
                        break;
                }
                errors[key] = !this.validator.check(value, validations);
                //console.log(key, value, !this.validator.check(value, "required"));
            }
            this.setState({
                errors: errors
            })
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        const { rulesRunning } = this.state;
        const { contractContext: { data: { isChileApple } } } = this.context

        let countryCode = this.props.entitlementDetails?.countryCode ?? "us";

        let applePaySession = window.ApplePaySession;
        let canMakePayments = typeof applePaySession != "undefined" && applePaySession != null ? applePaySession.canMakePayments() : null;
        let applePayAvailable = typeof applePaySession != "undefined" && applePaySession != null && canMakePayments != null && this.state.isApplePayEnabled ? true : false;

        console.log(applePayAvailable);
        this.validator.purgeFields();
        const billingStaticAddress = {
            PartyName: this.state.billingAddress.PartyName,
            address1: this.state.billingAddress.address1,
            address2: this.state.billingAddress.address2,
            city: this.state.billingAddress.city,
            state: this.state.billingAddress.state || '',
            stateId: this.state.billingAddress.stateId,
            zip: this.state.billingAddress.zip,
            country: this.state.billingAddress?.countryCode
        }

        return (
            <UseTranslationInClassComponent componentPathFromSrcFolder={'components/Vendor/Apple/IntakeLoss/Payment'}>
                {international => {
                    const {
                        country,
                        countryVars: {
                            currency,
                            symbol,
                            thousandsDelimiter,
                            decimalDelimiter
                        },
                        translations,
                        englishVars: {
                            deductibleName
                        }
                    } = international
                    const pageTitle = `${deductibleName} | AIG AppleCare+ with Theft and Loss Claims | Payment`;

                    const getAuthenticationErrorMessage = () => {
                        const phoneNumber = this.state.authenticationPhoneNumber || "";
                        const message = country == "DE" && this.state.payErrorContact == null
                            ? (translations.chaseFailed || this.state.authenticationErrorMessage)
                            : `${translations.chasePending || this.state.authenticationErrorMessage} ${phoneNumber}`;
                        return `${message.trim()}.`;
                    }
                    
                    const getAuthenticationLockMessage = () => {
                        return ( 
                            `${translations.authErrorPrefix || 
                                `Unfortunately there was an issue processing your payment; 
                                your card was not charged. 
                                To complete your claim, please contact one of our representatives at`
                            } 
                            ${appleServiceNumbers?.customerService[country?.toUpperCase()]} 
                            ${translations.authErrorSuffix || 'and reference your Case ID:'} ${this.state.caseNumber}.`
                        )
                    }

                    return (
                        <DocumentTitle title={pageTitle}>
                            <>
                                <div>
                                    {rulesRunning ?
                                        <div className="formBlock text-center">
                                            <Spinner animation="border" variant="primary" role="status" />
                                            <p>Processing Payment...</p>
                                        </div>
                                        :
                                        <Form>
                                            <FormGroup>
                                                <div className="formBlock">
                                                    {(applePayAvailable)
                                                        // || !applePayAvailable 
                                                        &&
                                                        <>
                                                            <div className={styles.paymentOptionsWrapper}>

                                                                {/* "OFFICIAL" APPLE PAY BUTTON. DOES NOT CURRENTLY WORK CORRECTLY */}
                                                                {/* <div class="apple-pay-button apple-pay-button-white-with-line" value="Applepay" onClick={this.handleApplePay}/> */}

                                                                {/* CUSTOM APPLE PAY BUTTON TO MATCH OUR STYLING. */}
                                                                <div className={classNames("radioButton", payOption, styles.applePay, "paymentCustomRadio")}
                                                                    tabIndex="0"
                                                                    aria-label={translations.applePayAria || "Apple Pay Option"}
                                                                    aria-checked={this.state.paymentMethod === "Applepay" ? "true" : "false"}
                                                                    role="radio" id="reach-skip-nav"
                                                                    onKeyPress={this.handleKeyDown}>
                                                                    <Input
                                                                        type="radio"
                                                                        role="radio"
                                                                        name="paymentList"
                                                                        tabIndex="0"
                                                                        value="Applepay"
                                                                        id="paytype1"
                                                                        onChange={this.handleSelectedPayment}
                                                                        checked={this.state.paymentMethod === "Applepay" ? true : false}
                                                                    />
                                                                    <Label className="radio-controls-label" for="paytype1">
                                                                        <Spinner color="light" animation={rulesRunning ? "border" : ""} />
                                                                        {!rulesRunning &&
                                                                            <img className={styles.payLogo} role="presentation" src={ApplePayIcon} />
                                                                        }
                                                                    </Label>
                                                                </div>
                                                                <div
                                                                    className={classNames("radioButton", payOption, "paymentCustomRadio")}
                                                                    tabIndex="0"
                                                                    aria-label={translations.cardTypeAria || "Credit or Debit card payment option"}
                                                                    aria-checked={this.state.paymentMethod === "ccard" ? "true" : "false"}
                                                                    role="radio"
                                                                    id="pay2Div"
                                                                    onKeyPress={this.handleKeyDown}
                                                                >
                                                                    <Input type="radio" role="radio" name="paymentList" id="paytype2" value="ccard" onChange={this.handleSelectedPayment} checked={this.state.paymentMethod === "ccard" ? true : false} />
                                                                    <Label className="radio-controls-label" for="paytype2">
                                                                        <img className={styles.payLogo} role="presentation" src={OutlineIcon} />Credit/Debit Card
                                                                    </Label>
                                                                </div>
                                                            </div>
                                                            {this.state.applePayError && <div className="errorMsg" role="alert">Error with payment, please try a different method</div>}
                                                        </>
                                                    }
                                                </div>
                                            </FormGroup>
                                            {this.state.paymentMethod === "ccard" || !applePayAvailable ?
                                                <>
                                                    <FormGroup className={(((this.state.errors.ccNumber || this.state.errors.ccInvalid) && this.state.validationFired) ? "form-error inputContainer" : "inputContainer")}>
                                                        <Label for="ccNumber" className="form-label">{translations.ccNumber || 'Credit Card Number'}</Label>
                                                        <span aria-hidden="true" className="required-indicator">*</span>
                                                        <CreditCardInput
                                                            onCreditCardTypeChanged={this.ccTypeChanged}
                                                            handleInputChange={this.handleInputChange}
                                                            arialabelledby="ccNumber" autoComplete="cc-number"
                                                            ariarequired="true" className="form-control"
                                                            name="ccNumber" id="ccNumber"
                                                        />
                                                        <CreditCardIcons
                                                            showAmex={countryCode.toLowerCase() === 'us'}
                                                            showDiscover={countryCode.toLowerCase() === 'us'}
                                                            ccType={this.state.ccType}
                                                        />
                                                        <input name="cardNoSafe" id="cardNoSafe" value="" style={{ display: 'none' }} />
                                                        {this.state.errors.ccInvalid && <div className="errorMsg" role="alert">{translations.errors?.ccNumInvalid || 'The credit card number is invalid.'}</div>}
                                                        {this.validator.message(
                                                            "creditCardNumber",
                                                            this.state.ccNumber,
                                                            this.props.entitlementDetails?.countryCode?.toLowerCase() === "us" 
                                                                ? "required|card_num" 
                                                                : "required|card_num|card_notsupported",
                                                            {
                                                                messages: {
                                                                    required: translations.errors?.ccNumRequired || 'Credit card number is required.',
                                                                    card_num: translations.errors?.ccNumInvalid || 'The credit card number is invalid.',
                                                                    card_notsupported: translations.errors?.ccNotAccepted || 'Payment type not accepted.'
                                                                }
                                                            }
                                                        )}
                                                    </FormGroup>
                                                    <div className="row multiColWrapper">
                                                        <Col sm="6">
                                                            <FormGroup className={(((this.state.errors.cardExpires || this.state.errors.ccExpired) && this.state.validationFired) ? "form-error" : "")}>
                                                                <Label for="cardExpires" className="form-label">{translations.expiry || 'Expiration Date'}</Label>
                                                                <span aria-hidden="true" className="required-indicator">*</span>
                                                                <Cleave options={{ date: true, datePattern: ['m', 'y'] }} aria-labelledby="cardExpires" autoComplete="cc-exp" className="form-control" type="text" placeholder="MM/YY" id="cardExpires" name="cardExpires" aria-required="true" onChange={this.handleInputChange} value={this.state.cardExpires} onBlur={() => this.validator.showMessageFor('cardExpires')} />
                                                                {this.state.errors.ccExpired && <div className="errorMsg" role="alert">{translations.errors?.cardExpired || 'The credit card being used has expired.'}</div>}
                                                                {this.validator.message(
                                                                    "expirationDate",
                                                                    this.state.cardExpires,
                                                                    "required",
                                                                    {
                                                                        messages: {
                                                                            required: translations.errors?.expiryRequired || 'Expiration date is required.'
                                                                        }
                                                                    }
                                                                )}
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm="6">
                                                            <FormGroup className={((this.state.errors.securityCode || this.state.errors.securityCodeInvalid) && this.state.validationFired) ? "form-error" : ""}>
                                                                <Label for="securityCode" className="form-label">{translations.securityCode || 'Security Code'}</Label>
                                                                <span aria-hidden="true" className="required-indicator">*</span>
                                                                <div className={styles.inputTooltip}>
                                                                    <span className={styles.hasTooltip}>
                                                                        <Button className={styles.tooltipButton} onClick={() => { ReactTooltip.show(this.tooltipRef) }}
                                                                            onKeyDown={(e) => e.keyCode === 27 && ReactTooltip.hide(this.tooltipRef)}
                                                                        >
                                                                            <Info
                                                                                ref={ref => this.tooltipRef = ref}
                                                                                data-tip data-for='tooltip'
                                                                                aria-label={translations.securityCodeTooltip || "3 or 4 digits on the back of the card (front of American Express)"}
                                                                                role="tooltip"
                                                                                className={styles.infoIcon}
                                                                            />
                                                                        </Button>
                                                                        <ReactTooltip
                                                                            id='tooltip'
                                                                            tabIndex="0"
                                                                            clickable="true"
                                                                            aria-haspopup='true'
                                                                            className={styles.tooltiptext}
                                                                            effect="solid"
                                                                            type="light"
                                                                            place={"bottom", "right"}
                                                                            border={true}
                                                                        >
                                                                            <img className={styles.ccPin} alt="Back of Card" src={SecurityIcon} /><br className="show-md-only" aria-hidden="true" />
                                                                            {translations.securityCodeTooltip || '3 or 4 digits on the back of the card (front of American Express)'}
                                                                        </ReactTooltip>
                                                                    </span>
                                                                    <Cleave
                                                                        options={{ numericOnly: true, blocks: [4] }}
                                                                        autoComplete="cc-csc"
                                                                        aria-labelledby="securityCode"
                                                                        className="form-control"
                                                                        aria-label={translations.securityCodeAria || "Security Code 3 or 4 digits on the back of the card (front of American Express)"}
                                                                        id="securityCode"
                                                                        name="securityCode"
                                                                        maxLength={4}
                                                                        aria-required="true"
                                                                        onChange={this.handleInputChange}
                                                                        value={this.state.securityCode}
                                                                        onBlur={() => this.validator.showMessageFor('securityCode')}
                                                                    />
                                                                </div>
                                                                {this.validator.message(
                                                                    "securityCode",
                                                                    this.state.securityCode,
                                                                    "required|cvv",
                                                                    {
                                                                        messages: {
                                                                            required: translations.errors?.cvvRequired || 'Security code is required.',
                                                                            cvv: translations.errors?.cvvInvalid || 'The security code is invalid.'
                                                                        }
                                                                    }
                                                                )}
                                                                {/* {this.state.errors.securityCodeInvalid && <div className="errorMsg" aria-live="polite">The security code is invalid.</div>} */}
                                                            </FormGroup>
                                                        </Col>

                                                    </div>
                                                    <fieldset aria-required="true">
                                                        <legend for="billingAddress" className="form-label">{translations.billingAddress || 'Billing Address'}<span aria-hidden="true" className="required-indicator">*</span></legend>
                                                        <div
                                                            className="checkbox"
                                                            aria-label={translations.billingAddressAria || "Mailing address checkbox is checked uncheck to edit address information"}
                                                        >
                                                            <Input className="mr-2" type="checkbox" id="mailingaddress" onChange={this.handleCheckAddress} checked={!this.state.customAddress} />
                                                            <Label
                                                                for="mailingaddress"
                                                                className={`checkboxLabel ${isChileApple ? 'southbridge' : ''}`}
                                                            >
                                                                {translations.useMailing || 'Use my mailing address'}
                                                            </Label>
                                                        </div>
                                                        {!this.state.customAddress ?
                                                            //<ul id="billingAddressStatic" className="noBullets pb-3">
                                                            //    <li>{this.state.PartyName}</li>
                                                            //    <li>{this.state.verifiedAddress.address1 + " " + ((this.state.verifiedAddress.address2 === null || this.state.verifiedAddress.address2 === undefined) ? '' : this.state.verifiedAddress.address2)}</li>
                                                            //    <li>{this.state.verifiedAddress.city + ", " + this.state.verifiedAddress.state + " " + this.state.verifiedAddress.zip}</li>
                                                            //</ul>
                                                            <StaticAddress
                                                                showCountry={countryCode.toLowerCase() !== this.props.entitlementDetails.shipToCountryCode}
                                                                addressData={this.props.entitlementDetails?.party?.serviceAddress}
                                                                countryCode={this.props.entitlementDetails?.party?.serviceAddress?.countryCode}
                                                                states={this.state.states}
                                                                clientId={this.state.clientId}
                                                            />
                                                            :
                                                            <AddressForm
                                                                allowVariableCountry={true}
                                                                onUpdateStates={this.handleChangeBillingCountry}
                                                                clientId={this.state.clientId}
                                                                states={this.state.billingStates}
                                                                address={this.state.billingAddress}
                                                                stateId={this.state.stateId}
                                                                handleInputChange={this.handleInputChange}
                                                                handleStateDropDown={this.handleStateDropDown}
                                                                validationFired={this.state.validationFired}
                                                                validator={this.validator}
                                                                countryCode={this.props.entitlementDetails?.countryCode}
                                                                isChileApple={isChileApple}
                                                                showCountryChangeModal={false}
                                                            />
                                                        }
                                                    </fieldset>
                                                </>
                                                : null
                                            }
                                            <Row className="formBlock">
                                                {(this.state.paymentMethod === "ccard" || !applePayAvailable) &&

                                                    <div>
                                                        {this.state.errors.billingAddressMatch &&
                                                            <div className="validationSummary">
                                                                {translations.errors?.billingAddressMatch
                                                                    // Die eingegebene Adresse und die Rechnungsadresse dieser Karte stimmen nicht überein. 
                                                                    || "The billing address does not match the card's records."
                                                                }
                                                            </div>
                                                        }
                                                        {this.state.errors.funds &&
                                                            <div className="validationSummary">
                                                                {translations.errors?.funds
                                                                    // Leider stehen genügend Mittel auf dieser Karte nicht zur verfügung. Bitte versuchen Sie es mal mit einer anderen Karte erneut.
                                                                    || 'Unfortunately there are not enough funds available on this card. Please try paying with a different card.'
                                                                }
                                                            </div>
                                                        }
                                                        {this.state.errors.internal &&
                                                            <div className="validationSummary">
                                                                {translations.errors?.internal
                                                                    // Ein fehler auf unserer Seite aufgetreten. Ihre Karte wurde nicht belastet. Bitte versuchen Sie mal zu einem neuen Zeitpunkt erneut.
                                                                    || 'Something went wrong processing your payment. This issue was on our side, not yours. Your card was not charged, please try again later.'
                                                                }
                                                            </div>
                                                        }
                                                        {this.state.errors.declined &&
                                                            <div className="validationSummary">
                                                                {translations.errors?.declined
                                                                    // Die Zahlung wurde abgelehnt. Bitte versuchen Sie es noch einmal mit einem anderen Karte erneut.
                                                                    || 'The credit card entered was declined. Please try paying with a different credit card.'
                                                                }
                                                            </div>
                                                        }
                                                        {this.state.errors.general &&
                                                            <div className="validationSummary">
                                                                {translations.errors?.general
                                                                    // Bei der Verarbeitung Ihrer Zahlung ist ein Problem aufgetreten. Bitte versuchen Sie es noch einmal erneut.
                                                                    || 'There was an issue processing your payment. Please try again.'
                                                                }
                                                            </div>
                                                        }
                                                        {this.state.errors.authenticationError && this.state.failedAuthPayments < 2 &&
                                                            <div className="validationSummary">
                                                                {getAuthenticationErrorMessage()}
                                                            </div>
                                                        }
                                                        {this.state.failedAuthPayments >= 2 &&
                                                            <div className="validationSummary">
                                                                {getAuthenticationLockMessage()}
                                                            </div>
                                                        }
                                                    </div>
                                                }

                                                <IntakeLossButtons
                                                    hideButtonBecauseOfApplePay={applePayAvailable && this.state.paymentMethod !== 'ccard'}
                                                    // PRIMARY BUTTON PROPS
                                                    buttonText={translations.continue || "Pay and Submit"}
                                                    onButtonClick={this.pay}
                                                    buttonDisabled={this.state.searching || this.state.failedAuthPayments >= 2}
                                                    buttonSpinning={this.state.searching}
                                                    buttonAriaLabel={translations.intakePayAria || `Click pay and submit to pay ${deductibleName.toLowerCase()} and complete claim setup or continue tabbing to review your claim summary`}
                                                    // FIRST LINK UNDER BUTTON
                                                    showLink1={true}
                                                    onLink1Click={this.onSaveForLaterAction}
                                                    link1AriaLabel={translations.saveBtnAria || "Opens a modal to save your claim"}
                                                    link1Text={translations.save || "Save For Later"}
                                                    // CANCEL LINK PROPS CONTROL CANCEL MODAL
                                                    saveClaimClickActionFromWithinCancelModal={this.onSaveForLaterAction}
                                                    caseNumberToCancel={this.state.caseNumber}
                                                    claimNumberToCancel={this.state.claimNumber}
                                                />
                                                {this.state.searching && (
                                                    <ProcessingModal isProcessing={this.state.searching} toggle={!(this.state.searching)} />
                                                )}

                                                {/* 
                                                    CODE ABOVE SEEMS TO BE WORKING PROPERLY AS OF 08/02/21. 
                                                    THE BELOW CODE IS OLD VERSION. DUE TO DEADLINES, PRESERVING IN CASE
                                                    THINGS BREAK. DELETE IF STILL FUNCTIONING AFTER NEXT ROUND OF UAT
                                                    IN AUGUST 2021.
                                                    -NPB
                                                */}

                                                {/* <div className="btnWrapper">
                                                        {this.state.paymentMethod === "ccard" || !applePayAvailable ?
                                                            <button className={classNames(btnPrimary, btnPay, btn)} aria-label={`Click pay and submit to pay ${deductible.toLowerCase()} and complete claim setup or continue tabbing to review your claim summary`} disabled={this.state.searching} aria-disabled="false" onClick={this.pay} >
                                                                <Spinner color="light" size="sm" animation={this.state.searching ? "border" : ""} />
                                                                {this.state.searching ? "" : "Pay and Submit"}
                                                            </button>
                                                            : null}
                                                        <a href="#" className={classNames("btnLink", ml15)} onClick={this.onSaveForLaterAction} aria-label="Opens a modal to save your claim">Save For Later</a>
                                                        <CancelModal role="button" buttonClassName={classNames("btnLink", ml15)} ariaLabel="Opens a modal to cancel your claim" buttonLabel="Cancel Claim" isButton={false} history={this.props.history} claimNumber={this.state.claimNumber} caseNumber={this.state.caseNumber}
                                                            onSaveForLaterAction={this.onSaveForLaterAction} />
                                                </div> */}
                                                {this.state.showConfirmation &&
                                                    <SaveModal
                                                        isOpen={this.state.showConfirmation}
                                                        showConfirmation={this.state.showConfirmation}
                                                        history={this.props.history}
                                                        continuing={this.state.isSaveForLater}
                                                        onCancel={this.onSaveCancel}
                                                        onSaveForLaterAction={this.saveForLater} client={"apple"}
                                                    />
                                                }
                                            </Row>
                                        </Form>
                                    }
                                </div>
                                {this.state.showThreeDSMethodRedirect &&
                                    <div className={styles.modalEmailContainer} style={{ display: 'none' }}>
                                        <div dangerouslySetInnerHTML={{ __html: this.state.ThreeDSMethodRedirectHtml }} />
                                    </div>
                                }
                                <Modal isOpen={this.state.openThreeDSChallenge} aria-modal="true" className="md" aria-labelledBy="Challenge" role="dialog">
                                    <div dangerouslySetInnerHTML={{ __html: this.state.ThreeDSChallengeRedirectHtml }} />
                                </Modal>
                            </>
                        </DocumentTitle>
                    );
                }
                }
            </UseTranslationInClassComponent>
        )
    }
}

Payment.contextType = CombinedLangContractContext;